export default {
  commonActions: {
    save: "salva",
    saveSettings: "salva impostazioni",
    submit: "invia",
    send: "invia",
    edit: "modificare",
    upload: "caricare",
    download: "scaricare",
    open: "open",
    close: "chiudi",
    delete: "elimina",
    passwordPolicy: "La password deve essere lunga almeno 8 caratteri e deve contenere lettere, numeri e caratteri speciali."
  },
  useExistingPolicy: {
    _labelActive: "Attiva",
    _labelMandatory: "Obbligatoria",
    _labelNotMandatory: "Non Obbligatoria",
    _labelOnlyThisEvent: "Limitata a questo evento",
    _noPolicies: "Nessuna Policy caricata",
    _name: "Privacy policy nome:"
  },
  createPolicy: {
    _small: "Gestione Privacy Policy Evento",
    _subtitle: "",
    _policyName: "Nome policy",
    _policyText: "Testo policy",
    _createPolicy: "Crea"
  },
  privacyPolicy: {
    _createNew: "CREA LA TUA PRIVACY POLICY",
    _chooseExisting: "PRIVACY POLICY CREATE"
  },
  groupManager: {
    _noDataMessage: "Ancora nessun utente in questo gruppo",
    _noDataHint: "Inserisci da qui"
  },
  modalError: {
    _title: "Qualcosa non va...",
    _buttonClose: "Chiudi",
    _unManagedError: "Errore non gestito"
  },
  modalWarning: {
    _title: "Attenzione",
    _buttonClose: "Chiudi",
    _warningNotImportedMessage0:
      "Non è stato impossibile importare alcuni elementi della tua lista.",
    _warningNotImportedMessage1:
      "Alcune volte questo è dovuto ad errori di formattazione, oppure esiste già un altro invitato con la stessa email nella lista degli ospiti.",
    _warningNotImportedMessage2: "Controlla la tua lista, e riprova.",
    _warningNotImportedLinkDownload: "Scarica la lista degli elementi non importati",
    _itemNotImportedRecap: "Numero non importati",
    _unManagedError: "Errore non gestito"
  },
  functionBar: {
    _new: "Nuovo",
    _events: "Eventi",
    _archive: "Archivio",
    _search: "Cerca",
    _tutorial: "Tutorial",
    _report: "Report "
  },
  attachments: {
    _selectattachments: "Seleziona il tuo allegato",
    _putattachmentstoevent: "Allega documenti al tuo evento, che desideri far visualizzare in App",
    _help: "In questa sezione è possibile caricare solo documenti in PDF.",
    _attachmentTypeSelect: "Seleziona tipo di allegato",
    _map: "Planimetria",
    _brochure: "Brochure",
    _invite: "Invito",
    _press: "Comunicato stampa",
    _upload: "Upload",
    _loadingMessage: "attendere",
    _mandatoryFields: "Seleziona un tipo di documento e un file dal tuo archivio",
    _filetype: "Tipo di documento *",
    _uploadsuccess: "Caricamento eseguito",
    _uploadfailed: "Caricamento fallito",
    _deleteAttachment: "Elimina Allegato"
  },
  attendeeMaxLimit: {
    _title: "Limite massimo di conferme",
    _subtitle: "Imposta il numero massimo di conferme accettabili per questo evento",
    _placeholder: "Numero massimo prenotazioni",
    _submit: "Salva impostazione",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito"
  },
  coverImage: {
    _updateDone: "aggiornamento eseguito",
    _loadingMessage: "attendere",
    _imgNotices: "Dimensioni consigliate 600x350 px, max 1 MB",
    _imgNoticesTY: "Dimensioni consigliate 1200x350 px, max 1 MB",
    _imgNoticesBG: "Dimensioni consigliate 1280x720 px, max 1 MB",
    _imgSizeExceed: "Dimensione max 1 MB superata!"
  },
  bannerImage: {
    _updateDone: "aggiornamento eseguito",
    _loadingMessage: "attendere",
    _imgNotices: "Dimensioni consigliate 400x1080 px, max 3 MB",
    _imgSizeExceed: "Dimensione max 3 MB superata!"
  },
  backgroundImage: {
    _updateDone: "aggiornamento eseguito",
    _loadingMessage: "attendere",
    _imgNotices: "Dimensioni consigliate 400x1080 px, max 3 MB",
    _imgSizeExceed: "Dimensione max 3 MB superata!"
  },
  createSelectEvent: {
    _public: "Pubblico",
    _publicboxtxt: "promuovi il tuo evento. Tutti gli utenti lo vedranno in qualsiasi luogo",
    _private: "Privato",
    _privateboxtxt: "crea il tuo evento e gestisci le tue guest list. Velocemente e semplicemente!",
    _notauthorized: "Contattaci per attivare questa opzione"
  },
  description: {
    _descriptionFieldPlaceHolder: "Inserisci una descrizione del tuo evento",
    _save: "salva",
    _create: "crea",
    _created: "creato",
    _modified: "modificato",
    _successmessage: "evento modificato con successo",
    _event: "evento",
    _pagetitlePrivate: "evento privato",
    _pagetitlePublic: "evento pubblico",
    _eventtype: "event type *",
    _eventname: "event name *",
    _startdate: "startdate *",
    _enddate: "enddate *",
    _location: "location",
    _flyer: "event flyer",
    _description: "description",
    _alternativeLanguageVersion: "Versione inglese",
    _alternativeLanguageVersionSmall:
      "Inserisci qui il tuo testo se la tua app supporta l'evento in multilingua",
    _locationErrorMessage: "Indirizzo non valido",
    _locationStatusSarching: "verifica in corso..",
    _undo: "cancella",
    _clear: "cancella",
    _next: "crea", // Modifica temporanea finche' non possiamo gestire il back
    _browse: "sfoglia",
    _loadingCoverMessage: "creazione evento in corso..",
    _creationFailed: "creazione fallita",
    _modificationFailed: "modifica fallita",
    _mandatoryFields: "I campi contrassegnati con l'asterisco sono obbligatori",
    _loadingMessage: "attendere"
  },
  eventList: {
    _unarchive: "ripristina",
    _private: "privato",
    _public: "pubblico",
    _what: "Cosa vuoi fare adesso ?",
    _publish: "Attiva",
    _edit: "Modifica",
    _delete: "Elimina",
    _archive: "Archivia",
    _duplicate: "Duplica",
    _jan: "Gen", // "Gen"
    _feb: "Feb", // "Feb",
    _mar: "Mar", // "Mar",
    _apr: "Apr", // "Apr",
    _may: "Mag", // "Mag",
    _jun: "Giu", // "Giu",
    _jul: "Lug", // "Lug",
    _aug: "Ago", // "Ago",
    _sep: "Set", // "Set",
    _oct: "Ott", // "Ott",
    _nov: "Nov", // "Nov",
    _dec: "Dic", // "Dic",
    _deletesuccess: "elemento cancellato"
  },
  months: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
  timeSlotManager: {
    _title: "Fasce orarie",
    _upload: "Carica il tuo calendario (.ics)",
    _deleteAllSlotsWarning:
      "Vuoi davvero eliminare tutti i tuoi slot? Questa azione è irreversibile.",
    _deleteAllSlots: "Elimina tutti gli slot",
    _openTableEditor: "Apri editor",
    _closeTableEditor: "Non salvare le modifiche",
    _saveTableEditor: "Salvare le modifiche",
    _innertitle: "Abilita le fasce orarie ",
    _maxLimitPerSlot: "Limite per fascia oraria ",
    _import_failure:
      "Impossibile trovare alcun intervallo di tempo idoneo per l'importazione. Riprovare più tardi",
    _import_success: " Fasce orarie importate con successo",
    _disabled: "(disabilitato)",
    _nothing_to_export: "Nessun dato da esportare",
    _export_data: "Esporta le tue prenotazioni come .ics"
  },
  modalEmailBuilder: {
    _title: "Qualcosa non va...",
    _buttonClose: "Chiudi",
    _unManagedError: "Errore non gestito",
    _templateCreationError: "Errore : template non creato",
    _templateSaved: "Template salvato",
    _emailCreationError: "Errore : template non creato",
    _emailSaved: "Email salvato",
    _save: "Salva",
    _confirm: "Conferma",
    _update: "Aggiornare",
    _cancel: "Annulla",
    _templateName: "Nome Template",
    _templateSubject: "Oggetto dell'e-mail",
    _backToTemplates: "Templates",
    _nameIsMandatory: "Il nome del modello e l'oggetto sono obbligatori",
    _areyousure: "Sei sicuro di voler cancellare questo template",
    _areyousure_cloning: "Vuoi davvero creare un nuovo modello usando questo?",
    _areyousure_discard: "Vuoi davvero uscire prima di salvare le modifiche?",
    _notemplatesave: "Template non salvato",
    _templateDelete: "Template eliminato",
    _notemplate: "Nessun template creato",
    _create: "Crea",
    _template_sent: "Email inviata!",
    _template_sent_error: "Email non inviata correttamente!",
    _use_save_button: "Utilizzare invece il pulsante Salva sulla destra"
  },
  eventPad: {
    _save: "salva",
    _duplicate: "Duplica",
    _create: "crea",
    _created: "creato",
    _modified: "modificato",
    _successmessage: "evento modificato con successo",
    _privateEvent: "EVENTO PRIVATO",
    _publicEvent: "EVENTO PUBBLICO",
    _event: "evento",
    _pagetitlePrivate: "evento privato",
    _pagetitlePublic: "evento pubblico",
    _eventtype: "event type *",
    _eventname: "event name *",
    _startdate: "startdate *",
    _enddate: "enddate *",
    _location: "location",
    _venue: "venue",
    _flyer: "event flyer",
    _description: "description",
    _locationErrorMessage: "Indirizzo non valido",
    _locationStatusSarching: "verifica in corso..",
    _undo: "cancella",
    _clear: "cancella",
    _next: "crea", // Modifica temporanea finche' non possiamo gestire il back
    _browse: "sfoglia",
    _loadingCoverMessage: "creazione evento in corso..",
    _creationFailed: "creazione fallita",
    _mandatoryFields: "I campi contrassegnati con l'asterisco sono obbligatori",
    _loadingMessage: "attendere",
    _resolvingAddressAlertMessage: "Attendere la verifica indirizzo"
  },
  eventPayable: {
    _title: "Evento a pagamento su altri sistemi",
    _subtitle: "Segnala che l'evento è a pagamento su altri sistemi agli utenti",
    _submit: "Salva Impostazione",
    _label: "L’evento che stai promuovendo è a pagamento?",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito"
  },
  externalReference: {
    _title: "Riferimento esterno",
    _subtitle: "Inserisci di seguito il riferimento dell'evento nel sistema esterno",
    _placeholder: "External Reference Id",
    _submit: "Salva impostazione",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito"
  },
  externalPurchaseEndpoint: {
    _title: "Endpoint di conferma esterno",
    _subtitle: "Inserisci di seguito il riferfimento URL della pagina di conferma esterna",
    _placeholder: "URL acquisto",
    _submit: "Salva impostazione",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito"
  },
  guestlist: {
    _refresh_table: "Aggiorna l'elenco degli ospiti",
    _title: "Guest list",
    _subtitle: "Da qui gestisci la lista dei tuoi invitati",
    _loadingMessage: "Attendere...",
    _add: "Aggiungi manualmente",
    _download: "Scarica lista",
    _upload: "Carica lista",
    _hubiloEvent: "ID Evento Hubilo",
    _hubiloSaved: "ID Evento Hubilo salvato",
    _reload: "Aggiorna lista",
    _list: "Lista",
    _guestnumber: "Numero invitati presenti in lista : ",
    _removelist: "Cancella tutti gli invitati",
    _nodata: "Nessun invitato",
    _guestadded: "aggiunto invitato",
    _listadded: "lista importata",
    _itemCancellato: "invitato rimosso",
    _listCancellata: "lista cancellata",
    _downloadlistAll: "Download tutta la lista",
    _downloadlist: "Download solo i Check-in",
    _downloadPlusOneList: "Download +1",
    _downloadActivities: "Scarica il report sull'attività",
    _downloadAttachments: "Scarica allegati",
    _sure:
      "Procedendo perderai irreversibilmente tutte le informazioni dei tuoi invitati, compresi gli RSVP già ottenuti. Vuoi procedere?",
    _sureForSingleItem:
      "Se procedi perderai irreversibilmente le informazioni del tuo invitato, compreso il suo RSVP. Vuoi procedere?",
    _guestupdated: "Ospite aggiornato",
    _errorInEditItems:
      "Non è possibile procedere. Errore durante l'aggiornamento dei dati dell'invitato.",
    _cannotProceed: "Non è possibile procedere",
    _canDeleteGuestWithPurchase:
      "Non è possibile procedere. L'invitato ha acquistato un titolo per accedere all'evento.",
    _loadInProgress: "Caricamento in corso...",
    _attendeePartnersMax: "Numero accompagnatori per persona",
    _attendeePartnersMaxUpdate: "aggiornamento eseguito",
    _listEmailsUpdated: "lista email aggiornata",
    _maxAttendeeExceeded: "Spiacente, la lista è piena!",
    _maxGuestSizeReached: "Non puoi aggiungere altri guest, hai raggiunto il limite di guest per evento, ",
    _upload_success: "Importazione dell'elenco degli ospiti ora",
    _upload_failure: "Impossibile caricare l'elenco degli ospiti",
    _discard_message:
      "Alcuni dei tuoi ospiti non sono stati importati correttamente. Fai clic per capire perché",
    _audience_title: "Filtra gli ospiti",
    _audience_subtitle: "Seleziona una delle opzioni seguenti per filtrare i tuoi ospiti",
    _autoPrint: "Stampa i badge automaticamente al checkin.",
    _localPrint: "Stampa i badge automaticamente al checkin manuale da web."
  },
  sponsor:{
    _title: "Lista Sponsor",
    _subtitle: "Da qui gestisci la lista dei tuoi sponsor",
    _list: "Lista",
    _addSponsor: "Aggiungi Sponsor",
    _sponsoradded: "Sponsor Aggiunto",
    _sponsorupdated: "Sponsor Aggiornato",
    _sponsorremoved: "Sponsor Rimosso",
    _sponsor: "Sponsor",
    _sponsorName: "Nome Sponsor",
    _sponorEmail: "Email Sponsor",
    _maxTickets: "Numero massimo di biglietti",
    _numberOfSponsors: "Numero di sponsor",
    _updated: "Aggiornato",
  },
  header: {
    _language: "lingua",
    _italian: "italiano",
    _english: "inglese",
    _francaise: "francese",
    _arab: "arabo",
    _logout: "Esci",
    _unmanagederror: "Errore non gestito",
    _languagechanged: "impostazione salvata"
  },
  insertTicketForm: {
    _title: "",
    _name: "descrizione *",
    _availability: "limite",
    _save: "salva",
    _price: "prezzo *",
    _type: "tipo *",
    _pay: "pagamento",
    _donation: "donazione",
    _nolimits: "nessun limite",
    _mandatoryFields: "I campi contrassegnati con l'asterisco sono obbligatori"
  },
  listSingleItemPurchaseList: {
    _title: "Inserisci i dati di un partecipante",
    _name: "Nome *",
    _surname: "Cognome *",
    _email: "E-Mail",
    _tel: "Telefono",
    _company: "Azienda",
    _nationality: "Nazionalità",
    _job: "Lavoro",
    _typology: "Tipo di ospite",
    _save: "salva",
    _loadingMessage: "Attendere...",
    _mandatoryFields: "I campi contrassegnati con l'asterisco sono obbligatori",
    _unManagedError: "Errore non gestito",
    _emailAlreadyPresentError: "Contatto non inserito. Email già presente in lista",
    _emailAlreadyPresentErrorToast: "contatto non inserito"
  },
  listSingleItem: {
    _title: "Inserisci i dati di un partecipante",
    _name: "Nome *",
    _surname: "Cognome *",
    _email: "E-Mail",
    _tel: "Telefono",
    _clear: "cancella",
    _save: "salva",
    _add_n_save: "Aggiungi e salva",
    _add_guest: "Aggiungi ospite",
    _sureRemove: "Stai per eliminare l'elemento selezionato, sicuro di voler procedere?",
    _loadingMessage: "Attendere...",
    _mandatoryFields: "I campi contrassegnati con l'asterisco sono obbligatori",
    _unManagedError: "Errore non gestito",
    _emailAlreadyPresentError: "Contatto non inserito. Email già presente in lista",
    _emailAlreadyPresentErrorToast: "contatto non inserito",
    _emailSyntaxError: "L'email dovrebbe essere come email@example.com",
    _emailSyntaxErrorToast: "L'email non è valida"
  },
  listUpload: {
    _title: "Carica una lista di partecipanti al tuo evento",
    _clear: "cancella",
    _load: "carica",
    _browse: "sfoglia",
    _loadingMessage: "Attendere...",
    _loadingComplete: "caricamento completato",
    _loadingError: "caricamento fallito",
    _itemImported: "importati",
    _mandatoryFields: "Nessun file selezionato",
    _unManagedError: "Errore non gestito",
    _downloadInstruction: "Scarica template per importazione",
    _incompleteListUploadWarningTitle: "Importazione incompleta", //  Controlla la lista degli elementi scartati.
    _incompleteListUploadWarning: "Alcuni nominativi non sono stati importati." //  Controlla la lista degli elementi scartati.
  },
  messages: {
    _uploadsuccess: "aggiornamento eseguito",
    _loadingMessage: "attendere",
    _inappmessagesimple: "Grazie per aver confermato",
    _inappmessagemodified:
      "Grazie per aver confermato. La presente conferma è informativa e non comporta l’acquisto e/o la prenotazione di un titolo di ingresso.",
    _inmailmessageintent:
      "Invia una mail di conferma all'utente al momento della conferma/acquisto",
    _savesettings: "Salva impostazioni",
    _title: "Email di conferma",
    _subtitle:
      "Definisci e personalizza il messaggio che l'utente riceverà via email dopo aver acquisito un titolo di accesso al tuo evento",
    _confirmmessagetitle: "Messaggio utente in applicazione",
    _confirmmessagesubtitle:
      "Seleziona il messaggio che gli utenti vedranno dopo aver confermato la loro partecipazione",
    _name: "Nome Mittente (Default : Penguinpass)",
    _email: "Email (Default : transactional@mypenguinpass.com)",
    _subject: "Oggetto (Default : Conferma Ordine)",
    _customizemsg: "Personalizza il messaggio",
    _editmsg: "Modifica il messaggio",
    _subjectExplanation: "Modifica il tuo modello per cambiare l'oggetto dell'e-mail di conferma",
    _registrationFormTitle: "Modulo di registrazione",
    _registrationFormDescription: "Invia un'e-mail di conferma una volta che i tuoi ospiti si sono registrati tramite il tuo modulo",
    _urlAfterRegisterDescription: "URL di reindirizzamento dopo la conferma della registrazione dell'account",
    _urlAfterRegister: "URL di reindirizzamento",
    _save: "Salva",
    _invalidUrl: "URL non valido",
  },
  buttonTabs: {
    INTERNAL_VIRTUAL_ROOM: "attiva la tua Penguinpass Room",
    INTERNAL_REGISTRATION_FORM: "attiva il tuo evento",
    EXTERNAL_VIRTUAL_ROOM: "attivare la stanza virtuale esterna",
    createNewTemplate: "creare nuovo",
    useExistingTemplate: "seleziona un modello",
    CONFIRMATION_EMAIL_ENABLED: "Sì, crea un'email di conferma",
    CONFIRMATION_EMAIL_DISABLED: "No, non è richiesto",
    SHOW_CATEGORY_FORM:"creare nuovo",
    SHOW_CATEGORY_TABLE:"gestire quelli esistenti",
    SHOW_EVENT_GROUP_FORM:"creare nuovo",
    SHOW_EVENT_GROUP_TABLE:"gestire quelli esistenti",
  },
  virtualRoomSettings: {
    _title: "Stanza virtuale",
    _subtitle: "Attiva la tua stanza virtuale Penguinpass o invita gli ospiti ad altre stanze virtuali esterne.",
    _externalRoomTitle: "Stanza esterna",
    _externalRoomSubtitle: "Aggiungi l'URL della stanza virtuale personalizzato (Zoom, Microsoft Teams, Google Hangouts, ecc.)",
    _internalRoomTitle: "Crea una pagina di destinazione per la tua stanza virtuale",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "Imposta l'orario della riunione",
    _instantMeeting: "Instant Meeting",
    _meetingTimeSubtitle: "Puoi selezionare la riunione istantanea per impostare automaticamente l'ora per ora",
    _meetingTimeSubtitle2: "Oppure imposta manualmente l'ora per dopo",
    _createdRoomsTitle: "Dettagli della stanza virtuale",
    _createdRoomsSubtitle: "",
    _previewTitle: "Anteprima",
    _hour: "ora",
    _hours: "ore",
    _scheduleRoom: "Programma la stanza",
    _moreSettingsTitle: "Altre impostazioni della stanza virtuale",
    _authNeededLabel: "Autenticazione necessaria",
    _moderatedChatLabel: "Chat moderata",
    _emailRequiredLabel: "Email all'iscrizione",
    _coverImageLabel: "Carica immagine di copertina",
    _authNeededExplanation: "Rendi la pagina accessibile solo agli utenti registrati.",
    _moderatedChatExplanation: "Questa funzione consente al moderatore di approvare o rifiutare tutti i messaggi inviati da ospiti e relatori",
    _emailRequiredExplanation: "Questa funzione attiva e disattiva un campo email obbligatorio nella pagina di iscrizione",
    _coverImageExplanation: "Personalizza la tua pagina di destinazione con un'immagine di copertina del tuo marchio.",
    _uploadImage: "Carica immagine (1 MB max)",
    _openRoom: "Open room",
    _cuesdayoom: "Crea una nuova pagina di destinazione",
    _customizeroom: "Personalizza la tua stanza virtuale",
    _authneeded: "Autenticazione necessaria",
    _moderatedchat: "Chat moderata",
    _uploadsuccess: "Aggiornato",
    _loadingMessage: "Attendi",
    _savesettings: "Salva impostazioni",
    _showPreview: "Mostra anteprima",
    _hidePreview: "Nascondi anteprima",
    _selectTemplate: "Applica un modello",
    _enableCustomVRoom: "Abilita stanza virtuale personalizzata",
    _roomscheduling: "Room scheduling",
    _roomschedulingsubtitle: "Qui puoi programmare quando iniziare la riunione",
    _instantmeeting: "Instant Meeting",
    _uploadCover: "Carica immagine di copertina",
    _publishpage: "Pubblica pagina",
    _unpublishpage: "Annulla pubblicazione pagina",
    _downloadlogs: "Scarica log",
    _goToPage: "Apri la pagina in un nuovo Tab",
    _pickNewerDate: "La data e l'ora di inizio devono essere nel futuro",
    _instantMeetingWarning: "Interrompi prima le stanze virtuali attive.",
    _pageSlug: "URL personalizzato",
    _applyPageSlug: "Applica",
    _pageSlugInUse: "URL Personalizzato già utilizzato",
    _pageSlugExplanation: "Personalizza l'indirizzo della tua  pagina",
    _scheduleRoomHalfAdvance: "Moderatori e presentatori(solo su webinar) potranno entrare mezz'ora in anticipo. Controlla lo stato della room, sarà attiva mezzora prima dell'orario schedulato."
  },
  registrationFormSettings: {
    _title: "Crea il tuo form di registrazione",
    _subtitle: "Crea qui la tua pagina di registrazione all'evento",
    _externalRoomTitle: "Stanza esterna",
    _externalRoomSubtitle: "Aggiungi l'URL della stanza virtuale personalizzato (Zoom, Microsoft Teams, Google Hangouts, ecc.)",
    _internalRoomTitle: "Scegli il tuo template",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "Imposta la durata della finestra di registrazione",
    _instantMeeting: "Attivazione istantanea del form",
    _meetingTimeSubtitle: "Inserisci la finestra temporale in cui rendere attivo il tuo form",
    _meetingTimeSubtitle2: "Oppure imposta manualmente l'ora per dopo",
    _createdRoomsTitle: "Pubblica il tuo form",
    _createdRoomsSubtitle: "",
    _previewTitle: "Anteprima",
    _hour: "ora",
    _hours: "ore",
    _scheduleRoom: "Programma l'attivazione",
    _moreSettingsTitle: "Altre impostazioni della stanza virtuale",
    _authNeededLabel: "Autenticazione necessaria",
    _moderatedChatLabel: "Chat moderata",
    _emailRequiredLabel: "Email all'iscrizione",
    _coverImageLabel: "Carica immagine di copertina",
    _authNeededExplanation: "Rendi la pagina accessibile solo agli utenti registrati.",
    _moderatedChatExplanation: "Questa funzione consente al moderatore di approvare o rifiutare tutti i messaggi inviati da ospiti e relatori",
    _emailRequiredExplanation: "Questa funzione attiva e disattiva un campo email obbligatorio nella pagina di iscrizione",
    _coverImageExplanation: "Personalizza la tua pagina di destinazione con un'immagine di copertina del tuo marchio.",
    _uploadImage: "Carica immagine (1 MB max)",
    _openRoom: "Open room",
    _cuesdayoom: "Crea una nuova pagina di destinazione",
    _customizeroom: "Personalizza la tua stanza virtuale",
    _authneeded: "Autenticazione necessaria",
    _moderatedchat: "Chat moderata",
    _uploadsuccess: "Aggiornato",
    _loadingMessage: "Attendi",
    _savesettings: "Salva impostazioni",
    _showPreview: "Mostra anteprima",
    _hidePreview: "Nascondi anteprima",
    _selectTemplate: "Applica un modello",
    _enableCustomVRoom: "Abilita stanza virtuale personalizzata",
    _roomscheduling: "Room scheduling",
    _roomschedulingsubtitle: "Qui puoi programmare quando iniziare la riunione",
    _instantmeeting: "Instant Meeting",
    _uploadCover: "Carica immagine di copertina",
    _publishpage: "Pubblica pagina",
    _unpublishpage: "Annulla pubblicazione pagina",
    _downloadlogs: "Scarica log",
    _goToPage: "Visualizza anteprima",
    _pickNewerDate: "La data e l'ora di inizio devono essere nel futuro",
    _instantMeetingWarning: "Interrompi prima le stanze virtuali attive.",
    _pageSlug: "URL personalizzato",
    _applyPageSlug: "Applica",
    _pageSlugInUse: "URL Personalizzato già utilizzato",
    _pageSlugExplanation: "Personalizza l'indirizzo della tua  pagina",
    _scheduleRoomHalfAdvance: "Moderatori e presentatori(solo su webinar) potranno entrare mezz'ora in anticipo. Controlla lo stato della room, sarà attiva mezzora prima dell'orario schedulato.",
    _customThankYou: "Ringraziamento personalizzato",
    _customThankYouExplanation: "Personalizza la tua pagina di ringraziamento",
    _customThankYouPlaceholder: "Inserisci il tuo ringraziamento personalizzato",
    _hideLoginBar: "Disabilita accesso account",
    _hideLoginBarExplanation: "Disabilita l'accesso all'account nel form di registrazione",
    _optionalEmail: "E-mail accompagnatori facoltativa",
    _optionalEmailExplanation: "Rendi facoltativo l'inserimento di un'e-mail per gli accompagnatori",
  },
  newEvent: {
    _create: "crea",
    _created: "creato",
    _event: "evento",
    _pagetitlePrivate: "evento privato",
    _pagetitlePublic: "evento pubblico",
    _eventtype: "Categoria *",
    _eventname: "Nome *",
    _startdate: "Data inizio *",
    _enddate: "Data fine *",
    _location: "Indirizzo *",
    _venue: "Venue",
    _virtualVenue: "Virtual venue",
    _descriptionIt: "Descrizione (It)",
    _descriptionEn: "Descrizione (En)",
    _undo: "cancella",
    _clear: "cancella",
    _next: "crea", // Modifica temporanea finche' non possiamo gestire il back
    _loadingCoverMessage: "creazione evento in corso..",
    _creationFailed: "creazione fallita",
    _mandatoryFields: "I campi contrassegnati con l'asterisco sono obbligatori",
    _placeNotValid: "Il valore del campo indirizzo non è valido",
    _rangeNotValid: "La durata dell'evento non può superare i 3 giorni"
  },
  overview: {
    _pending: "in attesa",
    _confirmed: "confermati",
    _checkin: "checkin",
    _checkinPlus: "checkin e +1",
    _prediction: "previsti",
    _total: "totale",
    _guestsAndCompanions: "confermati e +1"
  },
  promoteEvent: {
    _title: "Promozione evento",
    _subtitle:
      "Promuovi il tuo evento e pubblicalo sempre in cima di tutti gli eventi disponibili in ogni luogo",
    _submit: "Salva impostazione",
    _label: "Promuovi questo evento",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito"
  },
  purchaselist: {
    _title: "Lista prenotazioni",
    _subtitle:
      "Da qui gestisci l'elenco degli utenti che hanno prenotato la partecipazione al tuo evento",
    _loadingMessage: "Attendere...",
    _add: "Aggiungi manualmente",
    _download: "Scarica lista",
    _upload: "Carica lista",
    _list: "Lista",
    _guestnumber: "Numero di prenotazioni : ",
    _removelist: "Cancella tutti gli invitati",
    _nodata: "Nessuna prenotazione",
    _guestadded: "aggiunto invitato",
    _listadded: "lista importata",
    _itemCancellato: "invitato rimosso",
    _listCancellata: "lista cancellata",
    _downloadlistAll: "Download tutta la lista",
    _downloadlist: "Download solo i check-in"
  },
  report: {
    _title: "Statistiche",
    _subtitle: "Analisi dei dati raccolti",
    _timeLineCheckinChartLegend: "Il grafico mostra lo sviluppo e il flusso orario al punto di check in durante l'evento.",
    _attendeeConfirmationChartLegend: "Il grafico mostra il numero rsvp giornaliero",
    _export: "Scarica",
    _attendeeChart: "Elenco dei partecipanti",
    _timeLineAttendeeConfirmationTitle: "Timeline of Attendee Confirmation",
    _timeLineCheckInTitle: "Timeline Check-in",
    _totalAttendeeStatus: "Total Attendee Status",
    _export_request_received: "La tua richiesta è stata accettata. Vi informeremo a breve",
    _export_request_finished: "Il tuo rapporto è pronto. Clicca qui per scaricarlo",
    _export_failed: "Operazione fallita",
    _export_empty: "Il report che stai cercando di scaricare non contiene dati"
  },
  sidebar: {
    _dashboard: "Dashboard",
    _newevent: "Nuovo Evento",
    _downloadpenguinpass: "Guest App",
    _privacypolicy: "Privacy Policy",
    _contacts: "Contatti",
    _allevents: "Eventi",
    _eventArchived: "Archivio",
    _terms: "Termini e condizioni utilizzo",
    _linkTerms: "https://penguinpass-website.web.app/terms-and-conditions-it.html",
    _linkContacts: "https://www.penguinpass.it/contact-us.html",
    _faq: "/help/it/index.html",
    _privacypolicyURL: "https://penguinpass-website.web.app/privacy-policy-it.html", //
    _applestore: "https://itunes.apple.com/it/app/penguinpass/id1050702093?mt=8",
    _googlestore: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight",
    _downloadpenguinpasscheckinURL:
      "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight",
    _downloadpenguinpasscheckin: "Check-In App",
    _downloadpenguinpasscheckinURL_android: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight&hl=it",
    _downloadpenguinpasscheckinURL_ios: "https://apps.apple.com/it/app/penguin-check-in-light/id1572985276",
    _downloadpenguinpasscheckin_android: "Check-In App Android",
    _downloadpenguinpasscheckin_ios: "Check-In App IOS",
    _downloadpenguinpassbeacon: "Beacon App",
    _downloadpenguinpassbeaconURL:
      "https://itunes.apple.com/it/app/vbpenguinpass/id1204226558?mt=8",
    _members: "Il mio Team",
    _settings: "Impostazioni",
    _logoutConfirm: "Vuoi davvero disconnetterti?",
    _eventGroup:"Gruppi di eventi"
  },
  tickets: {
    _title: "Biglietti",
    _subtitle: "Gestisci la lista di biglietti associata al tuo evento",
    _nodata: "Evento gratuito",
    _nodata_payable: "Evento a pagamento su altri sistemi",
    _add: "Aggiungi un biglietto",
    _confirmDelete: "Vuoi veramente cancellare questo titolo",
    _itemCancellato: "titolo rimosso",
    _itemAdded: "titolo aggiunto",
    _test: ""
  },
  urlPrivacyPolicy: {
    _title: "Privacy Policy",
    _management: "Gestione Privacy Policy Evento",
    _subtitle:
      "Inserisci un riferimento URL esterno alle tue privacy policy che desideri far visualizzare ai tuoi utenti",
    _placeholder: "URL policy",
    _submit: "Salva impostazione",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito",
    _sure:
      "Procedendo perderai irreversibilmente tutte le informazioni su questa privacy policy. Vuoi procedere?"
  },
  visibilityManager: {
    _publish: "Attiva evento",
    _hidden: "Disattiva evento",
    _uploadsuccess: "aggiornamento eseguito",
    _archivesuccess: "evento archiviato",
    _unarchivesuccess: "evento ripristinato",
    _error: "Errore: inserire i dati obbligatori"
  },
  main: {
    _notauthorizedoption: "Opzione disattiva"
  },
  home: {
    _titleEventList: "Eventi Attivi"
  },
  eventCreateList: {
    _event: "Evento",
    _createString: "Crea il tuo primo elenco di invitati",
    _prospectList: "Lista invitati caricati",
    _add: "Aggiungi manualmente",
    _download: "Scarica lista",
    _upload: "Carica lista",
    _next: "Avanti",
    _loadingMessage: "Attendere...",
    _sure: "Sei sicuro di voler procedere?",
    _itemCancellato: "invitato rimosso"
  },
  events: {
    _noEvents: "Nessun evento trovato.",
    _progress: "Caricamento in corso...",
    _all_events: "Tutti gli eventi",
    _my_events: "I miei eventi",
    _favourites: "Preferiti",
    _archived_events: "Eventi archiviati",
    _search_result: "Risultati di ricerca"
  },
  singleEvent: {
    _event: "Evento",
    _createString: "Crea il tuo primo elenco di invitati",
    _prospectList: "Lista invitati caricati",
    _add: "Aggiungi manualmente",
    _download: "Scarica lista",
    _upload: "Carica lista",
    _next: "Avanti",
    _insertPlace: "Inserisci un luogo",
    _loadingMessage: "Attendere...",
    _description: "Descrizione",
    _emailMarketingWarning: "Attiva il tuo evento per utilizzare la funzione di email marketing",
    _updated: "Aggiornato"
  },
  datagrid: {
    _pending: "In Attesa",
    _confirmed: "Confermato",
    _checkin: "Check-In",
    _declined: "Declina",
    _will_be_back: "Torneremo",
    _checkout: "Check-out",
    _search: "Cerca",
    _selected: "Invitati selezionati",
    _selectAllGuests: "Tutti",
    _selectAllMarketingGuests: "Tutti gli ospiti idonei",
    _selectPending: "Ospiti in attesa",
    _selectNotRead: "Ospiti che non hanno letto le campagne precedenti",
    _selectNotSend: "Ospiti non inclusi nelle precedenti campagne",
    _selectNotSendConfirmed: "Ospiti confermati non inclusi nelle precedenti campagne",
    _selectUnRead: "Seleziona solo gli invitati che non hanno letto i precedenti invii",
    _selectUnSent: "Seleziona solo gli invitati non inclusi in invii precedenti",
    _selectConfirmed: "Ospiti confermati",
    _selectCheckedIn: "Ospiti checked-in",
    _selectCustom: "Seleziona personalizzato dall'elenco",
    _selectCustomField: "Usa Custom Fields",
    _selectTimeSlot: "Usa Fasce orarie",
    _edit_emails: "Modifica email",
    _list_emails: "Visualizza elenco",
    _list_events: "Visualizza elenco",
    _post_confirmation_blocked:
      "Siamo spiacenti, l'ospite è già stato confermato. Alcune informazioni non possono essere modificate",
    _guest_name: "Nome",
    _guest_surname: "Cognome",
    _guest_checkin: "Ospite Checked-in",
  },
  emailBuilderSelectMode: {
    _selectTemplateTitle: "Template",
    _selectTemplateText: "Comincia a creare il messaggio utilizzando un template",
    _new: "Nuovo",
    _newText: "Crea un nuovo messaggio"
  },
  textMarketing: {
    _title: "Campagna SMS",
    _titleWA: "Campagna WhatsApp",
    _subtitle: "Puoi creare messaggi per invitare gli ospiti al tuo evento (ricordati di mettere il prefisso nel formato 39 o 0039 e non lasciare alcuno spazio tra il prefisso e la prima cifra del numero di telefono)",
    _subtitleWA: `Qui puoi creare i messaggi che verranno inviati ai tuoi ospiti tramite Whatsapp. Il testo presentato di seguito NON è modificabile. Una volta inviato, i campi compresi tra le parentesi graffe verranno sostituiti con i dati dell'ospite.`,
    _create: "Crea una nuova campagna",
    _header: "Titolo",
    _body: "Testo",
    _sendToApprove: "Salva template",
    _edit:"Modifica",
    _audience_subtitle: "Filtra l'elenco per la tua campagna sms.",
    _recap: "Riepilogo dei messaggi inviate",
    _recapsubtitle: "Tieni traccia dei messaggi, rimanendo aggiornato sullo stato",
    _additionalSettingsTitle: "Altre impostazioni",
    _setRsvp:"Aggiungi link alla pagina di RSVP",
    _setRsvp_explain:"Selezionando il campo gli invitati potranno confermare la loro partecipazione tramite il link alla pagina di RSVP",
    _setConfirmation:"Aggiungi SMS di conferma",
    _setConfirmation_explain:"Selezionando il campo gli invitati riceveranno una conferma via SMS con il qr code per l'accesso all'evento",
  },
  directEmailMarketing: {
    _updatingCampaignStats: "aggiornamento statistiche in corso",
    _refreshStats: "Aggiorna statistiche",
    _title: "Campagna E-mail",
    _subtitle:
      "Puoi creare e-mail per invitare gli ospiti al tuo evento, nonché ricevere riepiloghi per gestire le tue e-mail.",
    _warnBeforeDelete: "Vuoi davvero annullare le modifiche ed eliminare la tua campagna?",
    _warnBeforeSend: "Sei sicuro di vole inviare la tua campagna?",
    _deleteCampaign: "Elimina campagna",
    _recap: "Riepilogo delle email inviate",
    _recapsubtitle: "Tieni traccia delle mail inviate, rimanendo aggiornato sullo stato",
    _name: "Nome (mittente)",
    _email: "Email (mittente)",
    _subject: "Oggetto",
    _send: "invia campagna",
    _save_changes: "salva le modifiche",
    _create: "Crea/Modifica  Campagna",
    _startfromtemplate: "seleziona template",
    _nocampaign: "ancora nessun invio",
    _message: "Messaggio",
    _contacts: "Firma",
    _logo: "Logo",
    _flyer: "Flyer",
    _clear: "cancella",
    _browse: "sfoglia",
    _undo: "cancella",
    _join: "Conferma via email",
    _join_explain:
      "Selezionando il campo gli invitati potranno confermare la loro partecipazione via email ",
    _setpartnermax: "Conferma +1",
    _setpartnermax_explain:
      "Selezionando il campo, gli ospiti possono indicare il numero dei loro conoscenti, sempre entro il limite massimo da te stabilito. si prega di notare, se l'ospite ha già indicato il numero dei suoi conoscenti, si consiglia di non modificare i dati visualizzati nella sezione elenco.",
    _setnote: "Richiedi nominativo del +1 e/o note",
    _setnote_explain:
      "Selezionando il campo i tuoi ospiti possono inserire i nomi dei loro conoscenti o richieste speciali. lì troverai nel campo note del file Excel dall'esportazione che puoi fare in qualsiasi momento dalla dashboard.",
    _imgNoticesLogo: "Caricare file .jpg/.png, max 1 MB",
    _imgNoticesFlyer: "Caricare file .jpg/.png, max 1 MB",
    _personalizedLabel: "Personalizza",
    _personalizedExplain:
      "Seleziona per personalizzare invio con il seguente messaggio \"Gentile Nome + cognome\"",
    _createEmail: "Crea E-mail",
    _modifyEmail: "Modifica E-mail",
    _username: "nome utente",
    _senderName: "Nome",
    _save: "Salva",
    _modify: "Modifica",
    _selectOneVerifiedEmail: "Seleziona un indirizzo e-mail verificato",
    _selectOneVerifiedDomain: "Seleziona un indirizzo domain verificato",
    _defaultEmailExplanation1: "Per modificare l'indirizzo e-mail, devi prima verificare il tuo nome di dominio o indirizzo e-mail.",
    _goToProfileSettings: "Vai a Impostazioni profilo",
    _defaultEmailExplanation2: "per modificare l'indirizzo email",
    _editSettings: "Modificare le impostazioni",
    _subjectExplanation: "Modifica il tuo modello per cambiare argomento della campagna",
    _campaign_sent: "La richiesta è stata presa. Ora stiamo elaborando la tua campagna",
    _recap_name: "Nome: ",
    _recap_email: "Email: ",
    _recap_subject: "Soggetto: ",
    _recap_total_recipient: "Destinatari(o) totale: ",
    _warning_email: "Seleziona prima un indirizzo email verificato",
    _warning_email_2: "Verifica prima un indirizzo email",
    _warning_domain: "Verifica e / o seleziona prima un dominio e un nome utente",
    _warning_default_email: "Impossibile inviare la campagna poiché l'indirizzo email non è valido",
    _warning_recipient: "Seleziona uno o più destinatari",
    _warning_template: "Seleziona o crea prima un modello e-mail",
    _sending_campaign: "Invio...",
    _cancel_campaign: "Annulla",
    _audience_title: "Pubblico di marketing",
    _audience_subtitle: "Filtra l'elenco per la tua campagna email.",
    _emailBuilderTitle: "Crea email",
    _emailBuilderSubtitle: "Utilizza il builder per creare il tuo design.",
    _additionalSettingsTitle: "Altre impostazioni di posta",
    _customFields_title: "Campi personalizzati",
    _customPolicy_title: "Informativa sulla privacy",
    _customFields_explain1: "Per ottenere ulteriori informazioni dai tuoi ospiti, puoi aggiungere campi personalizzati.",
    _customFields_explain2: "per gestire i campi personalizzati",
    _customPolicy_explain1: "Imposta l'informativa sulla privacy per ricevere informazioni dai tuoi ospiti.",
    _customPolicy_explain2: "per gestire le politiche sulla privacy.",
    _goToSettings: "Vai a Impostazioni",
    _confirmationEmail_title: "Email di conferma",
    _confirmationEmail_subtitle: "Una volta che l'ospite ha confermato che sarebbe presente all'evento o ha risposto con RSVP, puoi inviare un'e-mail di conferma per assicurarti che la sua risposta sia stata riconosciuta.",
    _noCampaigns: "Nessun messaggio è stato ancora inviato"
  },
  customFieldFilter: {
    _custom_field_filter_header: "Filtro",
    _custom_field_filter_question_choose:
      "Seleziona uno dei campi che desideri utilizzare come filtro",
    _custom_field_filter_answer_choose: "Seleziona una delle risposte (opzionale)",
    _custom_field_filter_answer_input: "Digita la risposta che stai cercando (opzionale)",
    _custom_field_filter_not_valid: "I filtri non sono validi. Seleziona un campo e una risposta"
  },
  searchEvent: {
    _eventType: "Categoria",
    _eventName: "Nome",
    _search: "Cerca",
    _description: "Descrizione",
    _address: "Indirizzo ",
    _sDate: "Data Inizio",
    _eDate: "Data Fine"
  },
  categoryManager: {
    _title: "Modifica Categoria Evento",
    _subtitle: "",
    _eventType: "categoria",
    _submit: "Salva Impostazione"
  },
  customFieldsManager: {
    _selectMax: "Opzioni selezionabili max",
    _insertAnswers: "Inserisci risposte",
    _title: "Modifica Custom Fields",
    _subtitle: "Gestione Custom Fields Evento",
    _submit: "Salva",
    _noCustomFields: "Nessun custom field impostato",
    _name: "Nome: ",
    _label: "Nome campo personalizzato",
    _answer: "Risposta ",
    _create: "Crea",
    _sure:
      "Procedendo perderai irreversibilmente tutte le informazioni su questo custom field. Vuoi procedere?",
    _createNew: "CREA UN CUSTOM FIELD",
    _chooseExisting: "CUSTOM FIELD CREATI",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito",
    _alreadyExists: "Custom field già presente",
    _checkInApp: "Mostra su Check-In App",
    _RSVP: "Mostra su RSVP",
    _registrationFormPage: "Mostra su Form di registrazione",
    _hideStaff: "Nascondi a Staff",
    _validateField: "Validazione campo",
    _mandatory: "Obbligatorio",
    OPEN_ANSWER: "Domanda aperta",
    MULTIPLE_CHOICE: "Scelta multipla",
    TEXT_ONLY: "Solo testo",
    MATRICOLA: "Matricola",
    DATE: "Data",
    _maxSelection: "Risposte selezionabili massime",
    _type: "Il Tipo",
    _limitReached: "Limite raggiunto per la risposta selezionata.",
    _validationWithInvalid: "Valore non valido per il campo.",
    _fieldName: "Campo",
    _questionTitle: "Domanda",
    _answerTitle: "Risposta",
    _limitAction: "Per favore cambia la tua risposta o aggiorna la pagina",
    _add_csv:
      "Inserisci qui le risposte dividendole con delle virgole (ex. risposta 1,risposta 2,risposta 3)",
    _selectTimeSlotWarning: "Seleziona almeno una fascia oraria",
    _confirm_disabling_option:
      "Alcuni dei tuoi ospiti hanno già selezionato questa risposta. Procedendo la risposta non sarà più selezionabile, tuttavia non vi sarà alcuna conseguenza per gli ospiti che hanno già selezionato la risposta. Desideri procedere?"
  },
  teamCustomFieldsManager: {
    _selectMax: "Opzioni selezionabili max",
    _insertAnswers: "Inserisci risposte",
    _title: "Gestisci i dati personali richiesti",
    _subtitle: "Gestione dati personali richeisti agli invitati",
    _submit: "Salva",
    _noCustomFields: "Nessun dato personale impostato",
    _name: "Nome: ",
    _label: "Nome campo personalizzato",
    _answer: "Risposta ",
    _create: "Crea",
    _sure:
      "Procedendo perderai irreversibilmente tutte le informazioni su questo dato personale. Vuoi procedere?",
    _createNew: "CREA UN CAMPO DATO PERSONALE",
    _chooseExisting: "CAMPI DATI PERSONALI CREATI",
    _updateDone: "aggiornamento eseguito",
    _error: "errore non gestito",
    _alreadyExists: "Dato personale già presente",
    _checkInApp: "Mostra su Check-In App",
    _RSVP: "Mostra su RSVP",
    _landingPage: "Mostra su Landing",
    _hideStaff: "Nascondi a Staff",
    _mandatory: "Mandatory",
    OPEN_ANSWER: "Open Answer",
    MULTIPLE_CHOICE: "Multiple Choice",
    _maxSelection: "Risposte selezionabili massime",
    _type: "Il Tipo",
    _limitReached: "Limite raggiunto per la risposta selezionata.",
    _questionTitle: "Domanda",
    _answerTitle: "Risposta",
    _limitAction: "Per favore cambia la tua risposta o aggiorna la pagina",
    _add_csv:
      "Inserisci qui le risposte dividendole con delle virgole (ex. risposta 1,risposta 2,risposta 3)",
    _selectTimeSlotWarning: "Seleziona almeno una fascia oraria",
    _confirm_disabling_option:
      "Alcuni dei tuoi ospiti hanno già selezionato questa risposta. Procedendo la risposta non sarà più selezionabile, tuttavia non vi sarà alcuna conseguenza per gli ospiti che hanno già selezionato la risposta. Desideri procedere?"
  },
  loginPage: {
    _login_title: "Benvenuto!",
    _login_description: " ",
    _email_hint: "Indirizzo email",
    _password_hint: "Password",
    _click_here: "Clicca qui",
    _password_recovery: "Ha dimenticato la password?",
    _submit: "Accesso",
    _remember_me: "Ricordati di me",
    _password_mandatory: "E 'richiesta la password",
    _email_not_valid: "L'email non è valida",
    _sso: "SSO",
    _confirm: "Conferma",
  },
  registerPage: {
    _register_title: "Registrati",
    _register_title_success: "Benvenuto!",
    _register_description:
      "Iscriviti GRATUITAMENTE per iniziare a provare Penguinpass e scrivici se hai bisogno di informazioni o richieste speciali per il tuo evento. Saremo felici di spiegarti quanto sia rivoluzionario il nostro sistema.",
    _email_hint: "Indirizzo email",
    _password_hint: "Password",
    _click_here: "Clicca qui",
    _password_recovery: "Ha dimenticato la password?",
    _submit: "Invia",
    _remember_me: "Ricordati di me",
    _privacy_policy_mandatory:
      "È necessario accettare la nostra politica sulla privacy e i termini e le condizioni per la registrazione",
    _email_not_valid: "L'email non è valida",
    _name_surname_mandatory: "Nome e cognome sono obbligatori",
    _company_mandatory: "Nome dell 'Azienda e obbligatorio",
    _password_mandatory: "E 'richiesta la password",
    _passwords_not_match: "Le password non sono le stesse",
    _p_name: "Nome*",
    _p_surname: "Cognome*",
    _p_email: "Email*",
    _p_company: "Nome dell 'Azienda*",
    _p_vat_number: "Partita IVA",
    _p_site: "Sito web",
    _p_city: "Città",
    _p_nation: "Nazionalità",
    _p_password: "Password *",
    _p_password_confirm: "Conferma la password*",
    _p_description: "Se lo desideri, inserisci un messaggio per noi",
    _after_register_title: "Prima di cominciare abbiamo bisogno di verificare la tua mail.",
    _after_register_description_1:
      "Riceverai all'indirizzo di posta elettronica da te indicato una mail per completare la verifica.",
    _after_register_description_2: "Se vuoi comunicare con noi scrivici a"
  },
  organizerSettings: {
    _pageTitle: "Impostazioni",
    _tabProfile: "Profilo",
    _tabPayment: "Dati di pagamento",
    _tabPassword: "Modifica Password",
    _tabCustomCategories: "Categorie",
    _tabEventSettings: "Eventi",
    _tabDomain: "Dominio",
    _tabUnsubscribers: "Unsubscribers",
    _tabDeleteAccount: "Elimina account",
    _profileHeader: "Info Registrazione",
    _profileSubHeader: "Riepilogo dai personali",
    _showInApp: "Questo nome sarà visibile nelle public pages",
    _profilePersonalSection: "Informazioni personali",
    _profileCompanySection: "Info dell'azienda",
    _profileBannerSection: "Sfondo",
    _profileBackgroundSection: "Sfondo",
    _placeholderName: "Nome",
    _placeholderSurname: "Cognome",
    _placeholderCompanyName: "Azienda",
    _placeholderVatNumber: "Partita IVA",
    _placeholderCap: "Cap",
    _placeholderCity: "Citta",
    _modify: "Modifica",
    _submit: "Submit",
    _add_new_email: "Verifica Email",
    _add_new_domain: "Verifica Dominio",
    _passwordHeader: "Modifica la tua password ",
    _domainHeader: "Verifica dell'identità",
    _select_identity_method: "Seleziona il tuo metodo di identità",
    _newDomainSection: "Nome dominio da verificare (esempio.com)",
    _newEmailSection: "Indirizzo e-mail da verificare (nome@esempio.com)",
    _email: "Email",
    _domain: "Dominio",
    _placeholderEmail: "Indirizzo e-mail da verificare (nome@esempio.com)",
    _placeholderDomain: "Nome dominio da verificare (esempio.com)",
    _selectIdentityType: "Seleziona",
    _default: "Default (no verification)",
    _email_recap: "Email identities",
    _domain_recap: "Custom Domains",
    _recap: "Identità di dominio",
    _recapSubtitle: "Qui puoi verificare i tuoi indirizzi email o domini personalizzati",
    _email_recap_subtitle:
      "Qui puoi controllare lo stato dei tuoi indirizzi e-mail utilizzati nell'e-mail marketing",
    _mail_from_desc_1:
      "La configurazione MAIL FROM personalizzata può aiutarti ad aumentare la reputazione e il marchio della consegna delle e-mail. È necessario abilitare e configurare solo se il provider DNS consente di modificare i valori MX per un sottodominio personalizzato (ad esempio aruba.it non consente di modificarli)",
    _mail_from_desc_2:
      "- Il valore MX è per l'invio di e-mail utilizzando il tuo dominio personalizzato.",
    _mail_from_desc_3: "- Il valore TXT è per la configurazione Sender Policy Framework (SPF).",
    _domain_desc_1:
      "Penguinpass utilizza Amazon Web Services (AWS), una delle soluzioni più affidabili sul mercato, per inviare e-mail. Per inviare e-mail per conto del tuo dominio personalizzato;",
    _domain_desc_2:
      "- Il valore TXT è per l'invio dell'autorizzazione per l'invio di e-mail per conto del tuo dominio.",
    _domain_desc_3: "- I valori CNAME sono per le impostazioni DKIM del tuo dominio.",
    _domain_desc_4:
      "Con queste due configurazioni, sarai in grado di inviare e-mail utilizzando Penguinpass Email Server senza perdere la capacità di inviare e-mail con il tuo server di posta originale.",
    _domain_desc_5:
      "Tutte le risposte alle e-mail inviate torneranno comunque al tuo server e-mail originale. Per ridurre la percentuale di spam, anche i valori CNAME sono contrassegnati come obbligatori.",
    _domain_desc_6:
      "Dopo aver verificato il tuo dominio, sarai in grado di utilizzare qualsiasi nome utente nel tuo dominio come mittente e-mail come `any_username@yourdomain.com`.",
    _domain_desc_7:
      "La tua richiesta di verifica scadrà dopo 48 ore. In tali casi, è necessario inviare una nuova richiesta di verifica.",
    _read_more: "leggi di più",
    _read_less: "mostra meno",
    _step: "Passo",
    _step_1: "Creare un dominio",
    _step_2: "Modifica della configurazione DNS",
    _step_3: "Tempo di attesa fino a 48 ore dopo la modifica della configurazione DNS",
    _refreshIdentities: "Fai clic per aggiornare",
    _updatingIdentities: "Caricamento",
    _no_data: "Nessun dato ancora",
    _settingsUpdated: "Settings updated!",
    _profileUpdated: "Informazioni profilo aggiornate!",
    _password_empty: "La password è vuota!",
    _newPassword_warning_message: "Le password non sono le stesse!",
    _password_updated: "Password aggiornata!",
    _same_password: "Seleziona una password diversa dalla precedente",
    _email_exists: "Email già presente!",
    _unsubscribersHeader: "Unsubscribers",
    _recap_unsubscribers: "Unsubscribers",
    _recapSubtitle_unsubscribers:
      "qui puoi controllare gli indirizzi e-mail che hanno annullato l'iscrizione alle mail del tuo profilo",
    _unsubscriber_number: "Numero di unsubscribers : ",
    _download_unsubscribers: "Scarica tutto l'elenco",
    _remove_existing: "Rimuovi prima le tue identità esistenti",
    _download_record_set: "Scarica record impostato come XLSX",
    _deleteDomain: "Elimina il dominio",
    _warn_before_delete_domain: "Vuoi veramente cancellare il tuo dominio?",
    _domain_exists:
      "Questo dominio è già stato verificato da un altro utente. Contattaci se sei il proprietario di questo dominio",
    _email_verification_exists:
      "Questa email è già stata verificata da un altro utente. Vi preghiamo di contattarci se siete i proprietari di questa email",
    _email_syntax_error: "L'email non è valida",
    _domain_syntax_error: "Il dominio non è valido",
    _email_domain_verification_exists:
      "Il dominio di questa email è già stato verificato da un altro utente. Contattaci se sei il proprietario di questo dominio.",
    _eventSettingsHeader: "Gestisci le impostazioni dei tuoi eventi",
    _eventSettingsSubtitle: "Qui puoi gestire le informazioni personali da chiedere ai tuoi ospiti quando si registrano ai tuoi eventi.",
    _customCategoriesHeader: "Gestisci le tue categorie personalizzate",
    _customCategoriesSubtitle: "Qui puoi gestire le tue categorie personalizzate per raggruppare i tuoi eventi come preferisci.",
    _eventGroupTitle: "Gestisci i tuoi gruppi di eventi",
    _eventGroupSubtitle: "Qui puoi gestire i tuoi gruppi di eventi per controllare la gestione degli accessi di uno o più eventi."
  },
  recoveryPassword: {
    _forgot_password_heading_text: "RECUPERA LA PASSWORD",
    _forgot_password_input_text: "Inserisci l'indirizzo email di recupero",
    _forgot_password_reset_text:
      "Inserisci la tua email qui sotto, ti invieremo un link per ripristinarlo, grazie.",
    _forgot_password_button_text: "RIPRISTINA",
    _successRecovery_title: "RECUPERO INIZIATO",
    _successecovery_message:
      "Ti abbiamo inviato un link per recuperare la tua password. Se hai ancora problemi scrivi a ",
    _newPassword_title: "Reimposta la tua password",
    _newPassword_title_update: "Aggiorna la tua password",
    _newPassword_description: "Inserisci la nuova password, e ripetila poi sotto per conferma.",
    _newPassword_description_update:
      "La tua password è scaduta Inserire la nuova password, quindi ripeterla di seguito per confermare.",
    _newPassword_summitButton: "Reimposta la tua password",
    _newPassword_summitButton_update: "Aggiorna la tua password",
    _newPassword_warning_message: "Le password non coincidono"
  },
  verifyAccount: {
    _send_link: "Invia",
    _email_label: "Inserisci la tua email",
    _resend_success_title: "Success!",
    _resend_success_subtitle: "Controlla la tua casella di posta. Si spera che sarà l'ultima volta!",
    _verification_success_title: "Success!",
    _verification_success_guest: "Verifica completata! Puoi tornare alla tua domanda ed eseguire il login",
    _verification_success_organizer: "Verifica completata! Puoi andare alla pagina di accesso e utilizzare la dashboard",
    _verification_failed_title: "Fallito!",
    _verification_failed: "Verifica non riuscita",
    _verification_expired: "Il link di verifica è scaduto. Se desideri verificare di nuovo, digita la tua email",
    _joinOk: "La registrazione all'evento è andata a buon fine!",
    _joinErr: "Ops, la registrazione all'evento non è andata a buon fine, effettua il login e riprova."
  },
  termsAndConditions: {
    _terms_accept: "e accetto il",
    _privacy_accept: "Ho letto",
    _privacy_title: "l'informativa sulla privacy",
    _terms_title: "termini e condizioni del servizio",
    _mandatory: "(obbligatoria)",
    _linkTerms: "https://penguinpass-website.web.app/terms-and-conditions-it.html",
    _privacypolicyURL: "https://penguinpass-website.web.app/privacy-policy-it.html",
    _updates:
      "Autorizzo il trattamento dei miei dati personali per rimanere informato sugli aggiornamenti del prodotto",
    _marketing:
      "Autorizzo il trattamento dei miei dati personali a scopi di marketing, inclusi quelli di terze parti"
  },
  eventCategories: {
    _contentEvent: "CONTENT EVENT",
    _pressDay: "PRESS DAY",
    _fashionShow: "FASHION SHOW",
    _corporateEvent: "CORPORATE EVENT",
    _fashionEvent: "FASHION EVENT",
    _onInvitation: "ON INVITATION",
    _cocktailParty: "COCKTAIL PARTY"
  },
  eventCategoriesAsOnDB: {
    _contentEvent: "CONTENT_EVENT",
    _pressDay: "PRESS_DAY",
    _fashionShow: "FASHION_SHOW",
    _corporateEvent: "CORPORATE_EVENT",
    _fashionEvent: "FASHION_EVENT",
    _onInvitation: "ON_INVITATION",
    _cocktailParty: "COCKTAIL_PARTY"
  },
  verificationResult: {
    _success_title: "SUCCESSO!",
    _success_message:
      "Grazie per la verifica Ora puoi usare l'email marketing con stile usando il tuo indirizzo email!",
    _failure_title: "Sorry!",
    _failure_message: "Qualcosa è andato storto. Riprova o non esitare a contattarci"
  },
  joinPage: {
    _join_title_accepted: "GRAZIE PER AVER CONFERMATO QUESTO INVITO RISERVATO A:",
    _join_title_declined: "HAI CONFERMATO CHE NON PARTECIPERAI ALL'EVENTO",
    _join_title_already_accept: "HAI GIA' CONFERMATO CHE PARTECIPERAI ALL'EVENTO.",
    _join_title_already_decline: "HAI GIA' CONFERMATO CHE NON PARTECIPERAI ALL'EVENTO.",
    _join_reservedfor: "QUESTO INVITO È RISERVATO A:",
    _join_description:
      "Leggi e conferma le politiche della privacy e i termini e condizioni per completare la conferma.",
    _join_confirm: "Conferma",
    _join_confirm_decline: "Rifiuta",
    _join_alert_policy: "Policy obbligatoria non accettata.",
    _join_alert_fields: "Campi obbligatori non inseriti.",
    _privacy_policy: "Privacy Policy",
    _custom_fields: "Informazioni aggiuntive",
    _mandatory: "Obbligatoria",
    _join_error_invalid_invitation: "Invito non trovato o invalido",
    _join_error_event_not_found: "Evento non trovato",
    _join_error_event_full:
      "Siamo spiacenti di informarla che le liste per questo evento sono chiuse.",
    _join_error_guest_not_found: "Invito non trovato o invalido",
    _join_error_policies_not_accepted:
      "Si prega di leggere e confermare la nostra politica sulla privacy e i termini e le condizioni al fine di completare il processo.",
    _join_error_unhandled: "Invito non trovato o invalido",
    _number_of_guests: "Seleziona il numero dei tuoi accompagnatori",
    _info_of_guests: "Per favore inserisci il nome dei tuoi ospiti",
    _guest: "Ospite",
    _guest_name: "Nome",
    _guest_surname: "Cognome"
  },
  registrationPage: {
    _eventFull: "Ops, l'evento è al completo ora. Controlla più tardi se sono tornati disponibili dei posti.",
    _eventFull2: "Ops, l'evento è al completo ora. Controlla più tardi se sono tornati disponibili dei posti, oppure riduci il numero di ospiti che stai portando con te se aggiunti.",
    _title: "Registrati per partecipare all'evento",
    _loggedInAs: "Sei loggato come",
    _logout: "Esci",
    _alreadyAccount: "Hai già un account?",
    _login: "Accedi",
    _signup: "Registrati",
    _prefillData: "per precompilare con i tuoi dati",
    _mandatory: "(Obbligatorio)",
    _optional: "(Facoltativo)",
    _firstName: "Nome",
    _lastName: "Cognome",
    _password: "Password",
    _retypePassword: "Ridigita password",
    _alreadyJoined: "Ti sei già iscritto a questo evento.",
    _takeSomeone: "Vuoi portare qualcuno con te?",
    _personsWithYou: "Persone da portare con te",
    _personsUpTo: "Puoi prendere fino a",
    _persons: "persone.",
    _person: "Persona",
    _register: "Registrati",
    _thankYouWaiting: "Grazie per esserti registrato a questo evento, ti abbiamo inviato un'e-mail per confermare il tuo account e completare la registrazione all'evento.",
    _thankYouWaiting2: "Grazie per esserti registrato, ti abbiamo inviato un'e-mail per confermare il tuo account.",
    _emailInUse: "Email già registrata, effettua il login per partecipare all'evento.",
    _emailInUse2: "Email già registrata.",
    _thankYou: "Grazie per esserti registrato a questo evento.",
    _basicInformation: "INFORMAZIONI DI BASE",
    _setPassword: "IMPOSTA PASSWORD",
    _moreInformation: "MAGGIORI INFORMAZIONI",
    _bookTimeSlots: "SELEZIONA LA FASCIA ORARIA"
  },
  unsubscribePage: {
    _title: "Mailing List",
    _action_confirm: "Fai clic di seguito se desideri interrompere la ricezione di email da questo organizzatore. Potrai iscriverti nuovamente in qualsiasi momento da questa pagina.",
    _unsubscribe_confirm: "Fai clic per annullare l'iscrizione",
    _thank_you_for_unsubscription: "Sei stato rimosso dalla mailing list di questo organizzatore, se desideri iscriverti di nuovo fai clic sul pulsante qui sotto. Per ulteriori informazioni, contatta",
    _resubscribe_reverse: "Fai clic per iscriverti di nuovo",
    _thank_you_for_resubscribe: "Grazie per esserti iscritto!",
    _unsubscribe_reverse: "Annulla modifiche",
    _resubscribe_confirm: "Fai clic per iscriverti"
  },
  ElementTable: {
    name: "Nome",
    surname: "Cognome",
    email: "E-mail"
  },
  teamPage: {
    _new_member: "Aggiungi email nuovo membro",
    _role: "Ruolo",
    _invite: "Invia l'invito",
    _invite_title: "Aggiungi membro",
    _invite_subtitle:
      "Qui puoi aggiungere nuovi membri al tuo team. Riceveranno un'e-mail di invito",
    _team_table_title: "Membri del Team",
    _team_table_subtitle: "Qui puoi ispezionare/modificare i membri del tuo team",
    _page_title: "Il mio Team",
    _invitation_sent: "Invito spedito!",
    _error_email_invalid: "E 'richiesta una mail valida",
    _error_role_invalid: "Scegli un ruolo",
    _updated: "Aggiornato",
    _warning_remove: "Vuoi veramente rimuovere",
    _post_remove: "L'utente verrà eliminato presto"
  },
  modalBulkAction: {
    _user_selected: "utente(i) selezionati",
    _cancel: "Annulla",
    _confirm: "Conferma",
    _search_event: "Cerca",
    _users_added: "Utenti aggiunti correttamente",
    _users_removed: "Utenti rimossi correttamente",
    _failed: "Operazione fallita",
    addToEvent: "Assegna a un evento",
    removeFromEvent: "Rimuovi da un evento",
    clearSelection: "Cancella selezione",
    _bulk_action: "Azione in blocco",
    _options: "Opzioni",
    _warning_select_users: "Seleziona prima alcuni utenti",
    _warning_select_event: "Seleziona prima un evento"
  },
  errorsNotFound: {
    _page_not_found: "Pagina non trovata",
    _page_not_found_desc:
      "Questa pagina potrebbe essere privata o potresti non aver richiesto l'autorizzazione per vederla.",
    _go_to_home: "Vai alla pagina principale",
    _user_not_found: "Utente non trovato",
  },
  badgePrinting: {
    _loadingMessage: "attendere",
    _title: "Badge",
    _innertitle: "Attiva il badge printing",
    _directPrint: "Stampa diretta tramite app",
    _save: "Salva",
    _saved: "Badge salvato",
    _reset: "Reset",
    _formTitle: "Titolo",
    _formDescription: "Descrizione",
    _formPageW: "Larghezza pagina",
    _formPageH: "Altezza pagina",
    _formFileUpload: "Clicca per caricare",
    _formFileUploadTips: "file jpg/png con una dimensione inferiore a 500 kb",
    _formTextColor: "Colore testo",
    _formBorderColor: "Colore bordo",
    _formField1: "Campo 1",
    _formField2: "Campo 2",
    _formCustomLayout: "Codice layout"
  },
  recordings: {
    _page_title: "Registrazioni",
    _subtitle: "In questa sezione puoi scaricare tutte le registrazioni.",
    _warning_remove: "Vuoi veramente rimuovere",
    _post_remove: "Registrazione rimossa"
  },
  customCategories: {
    _label: "Nome categoria",
    _submit: "Crea",
    _created: "Creato",
    _failed: "Fallito",
    _warnBeforeDelete: "Alcuni dei tuoi eventi utilizzano questa categoria. Pertanto, questa operazione contrassegnerà la categoria come eliminata e ne disabiliterà l'utilizzo ulteriore per continuare a supportarli. Vuoi continuare?",
  },
  eventGroup: {
    _newGroup: "Nome univoco del gruppo",
    _groupName: "Nome gruppo",
    _removeFromGroup: "Rimuovi",
    _markAsController: "Segna come controller",
    _markedAsController: "Contrassegnato come controller",
    _submit: "Crea",
    _created: "Creato",
    _save: "Salva",
    _editGroup: "Modifica gruppo eventi",
    _cancel: "Annulla",
    _fallito: "Fallito",
    _addEvent: "Aggiungi evento",
    _searchEvents: "Cerca eventi",
    _eventGroupExist: "Il nome di questo gruppo di eventi esiste già, prova qualcosa di nuovo",
    _selectController: "Seleziona almeno un evento controller",
    _selectNonController: "Seleziona almeno un evento non controller",
    _eventListEmpty: "Aggiungi prima l'evento",
    _groupNameMissing: "Manca il nome del gruppo",
    _warnBeforeDelete: "Sei sicuro di voler eliminare questo gruppo di eventi? L'eliminazione di questo gruppo di eventi non avrà effetto sui singoli eventi in questo gruppo.",
    _warnBeforeDeletingLastEvent: "Non puoi rimuovere tutti gli eventi all'interno di un gruppo. Considera invece l'eliminazione del gruppo.",
  },
  notify: {
    _page_title: "Push Notifications",
    _subtitle: "Crea notifiche push per specifici eventi o per comunicare aggiornamenti agli ospiti",
    _no_notifications: "Nessuna notifica è stata mandata",
    _create: "Crea nuova",
    _create_section_title: "Crea una nuova notifica push",
    _title: "Titolo",
    _title_placeholder: "Inserisci il titolo",
    _body: "Contenuto",
    _body_placeholder: "Inserisci il contenuto",
    _recap_section_title: "Resoconto notifiche",
    _user_group_section_title: "Scegli gli utenti",
    _title_recommendation: "Consigliato usare al massimo 10 parole",
    _submit: "Invia",
    _all_users: "Tutti gli ospiti di tutti gli eventi ",
    _subtitle_user_group: "Le notifiche sono riservate agli utenti che hanno scaricato la guest application e parteciperanno all'evento",
    _warnBeforeSend: "Sei sicuro di volere inviare la notifica?",
    _sending: "Invio in corso",
  },
  showOnApp: {
    _title: "Mostra in App e in Landing Page",
    _description: "Mostra questo evento nella guest app e in landing page",
    _no_password: "Disabilita registrazione",
  },
  publicPages: {
    _book_test: "Prenota un test",
    _download_ticket: "Scarica il biglietto",
    _certify_test: "Certifica risultato test",
    _join: "Partecipa",
    _reset_password: "Resetta password",
    _reset_message: "Riceverai un email da mailing@penguinpass.it per impostare una nuova password.",
    _password: "Password",
    _forgot_password: "Password dimenticata?",
    _back_login: "Torna al Login",
    _reset: "Resetta",
    _register: "Registrati",
    _name: "Nome",
    _surname: "Cognome",
    _email: "Email",
    _test_not_verified: "Test non verificato.",
    _test_not_verified_sub: "Devi effettuare il test per visualizzare il biglietto, se hai effettuato il test accertati che tu sia nell'intervallo di tempo richiesto prima dell'evento."
  },
  c19compliance: {
    _title: "REGOLAMENTO COVID-19",
    _description: "Abilita controllo",
    timePeriod: "Imposta periodo di tempo",
    subTimePeriod: "Imposta l'intervallo di tempo prima dell'evento durante il quale il test sarà considerato valido"
  },
  oauth: {
    _redirecting_you: "Attendi, stiamo redirezionando la tua richiesta"
  },
  RSVPImageHeader: {
    _title: "Personalizza la Thank you page",
    _innertitle: "Abilita immagine",
    _subtitle: "Qui puoi aggiungere un logo per personalizzare la thank you page che i tuoi ospiti vedranno confermando o rifiutando i tuoi inviti"
  }
};
