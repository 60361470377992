export default {
  commonActions: {
    save: "save",
    saveSettings: "save settings",
    submit: "submit",
    send: "send",
    edit: "edit",
    upload: "upload",
    download: "download",
    open: "open",
    close: "close",
    delete: "delete",
    passwordPolicy:
      "Password must be at least 8 characters long, must contain letters, digits and special characters",
  },
  useExistingPolicy: {
    _labelActive: "Active",
    _labelMandatory: "Mandatory",
    _labelNotMandatory: "Not Mandatory",
    _labelOnlyThisEvent: "Only for this event",
    _noPolicies: "No Policies",
    _name: "Privacy policy name:",
  },
  createPolicy: {
    _small: "Privacy policy event management",
    _subtitle: "",
    _policyName: "Privacy Policy Name",
    _policyText: "Privacy Policy Text",
    _createPolicy: "Create",
  },
  privacyPolicy: {
    _createNew: "CREATE YOUR PRIVACY POLICY",
    _chooseExisting: "PRIVACY POLICY CREATED",
    _save: "SAVE",
  },
  groupManager: {
    _noDataMessage: "No Users in thig group",
    _noDataHint: "Add one User from here",
  },
  modalError: {
    _title: "Something is wrong...",
    _buttonClose: "Close",
    _unManagedError: "Error not handled",
  },
  modalWarning: {
    _title: "Warning",
    _buttonClose: "Close",
    _warningNotImportedMessage0: "Some records of your list were discarded.",
    _warningNotImportedMessage1:
      "Sometimes this problem happens for some formatting error in your list, or a guest with the same email already exist on your event guest list.",
    _warningNotImportedMessage2: "Check your list and retry.",
    _warningNotImportedLinkDownload: "Download the discarded item list",
    _itemNotImportedRecap: "Number of item not imported",
    _unManagedError: "Error not handled",
  },
  functionBar: {
    _new: "New",
    _events: "Events",
    _tutorial: "Tutorial",
    _report: "Report",
    _archive: "Archive",
    _search: "Search",
  },
  attachments: {
    _selectattachments: "Choose an annex",
    _putattachmentstoevent: "Attach files to your event, they'll be displayed on the App",
    _help: "Only .pdf extension are allowed ",
    _attachmentTypeSelect: "Attachment type",
    _map: "Floor plan",
    _brochure: "Brochure",
    _invite: "Invitation",
    _press: "Press release",
    _upload: "Upload",
    _loadingMessage: "Wait",
    _mandatoryFields: "Pick a file from your archive ",
    _filetype: "Document type *",
    _uploadsuccess: "Upload succeed",
    _uploadfailed: "Upload failed",
    _deleteAttachment: "Delete Attachment",
  },
  attendeeMaxLimit: {
    _title: "Attendee Max Limit",
    _subtitle: "Purchase",
    _placeholder: "Maximum number of reservation.",
    _submit: "Save",
    _updateDone: "Updated",
    _error: "error not handled",
  },
  coverImage: {
    _updateDone: "Updated",
    _loadingMessage: "Wait",
    _imgNotices: "Recommended size 600x350 px, max 1 MB",
    _imgNoticesTY: "Recommended size 1200x350 px, max 1 MB",
    _imgNoticesBG: "Recommended size 1280x720 px, max 1 MB",

    _imgSizeExceed: "Max size 1 MB exceeded!",
  },
  backgroundImage: {
    _updateDone: "Updated",
    _loadingMessage: "Wait",
    _imgNotices: "Recommended size 600x350 px, max 1 MB",
    _imgNoticesTY: "Recommended size 1200x350 px, max 1 MB",
    _imgSizeExceed: "Max size 1 MB exceeded!",
  },
  createSelectEvent: {
    _public: "Public",
    _publicboxtxt: "Promote your event. All your users will see it everywhere they are.",
    _private: "Private",
    _privateboxtxt: "create your event and manage your guest lists.Quick and easy!",
    _notauthorized: "Contact us to validate this option.",
  },
  description: {
    _descriptionFieldPlaceHolder: "Add a description to your event",
    _save: "Save",
    _create: "Create",
    _created: "Created",
    _modified: "Edited",
    _successmessage: "The event has been successfully modified",
    _event: "Event",
    _pagetitlePrivate: "Private event",
    _pagetitlePublic: "Public event",
    _eventtype: "Event type *",
    _eventname: "Event name *",
    _startdate: "Startdate *",
    _enddate: "Enddate *",
    _location: "Location",
    _flyer: "Event flyer",
    _description: "Description",
    _alternativeLanguageVersion: "Alternative Text Version",
    _alternativeLanguageVersionSmall:
      "Write here your text if your guest app support multi language event",
    _locationErrorMessage: "Address not valid",
    _locationStatusSarching: "Verifying ..",
    _undo: "Celete",
    _clear: "Cancel",
    _next: "Create", // Modifica temporanea finche' non possiamo gestire il back
    _browse: "Choose",
    _loadingCoverMessage: "We're creating the event.",
    _creationFailed: "Creation failed",
    _modificationFailed: "Change failed ",
    _mandatoryFields: "The fields marked by asterisk (*) are mandatory ",
    _loadingMessage: "Wait",
  },
  eventList: {
    _archived: "Archived",
    _unarchive: "UnArchive",
    _private: "Private",
    _public: "Public",
    _what: "What do you want to do now?",
    _publish: "Activate",
    _edit: "Edit",
    _delete: "Delete",
    _archive: "Archive",
    _duplicate: "Duplicate",
    _jan: "Jan", // "Gen"
    _feb: "Feb", // "Feb",
    _mar: "Mar", // "Mar",
    _apr: "Apr", // "Apr",
    _may: "May", // "Mag",
    _jun: "Jun", // "Giu",
    _jul: "Jul", // "Lug",
    _aug: "Aug", // "Ago",
    _sep: "Sep", // "Set",
    _oct: "Oct", // "Ott",
    _nov: "Nov", // "Nov",
    _dec: "Dec", // "Dic",
  },
  months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  timeSlotManager: {
    _title: "Time slots",
    _deleteAllSlots: "Delete all slots",
    _openTableEditor: "Open editor",
    _closeTableEditor: "Discard changes",
    _saveTableEditor: "Save changes",
    _deleteAllSlotsWarning:
      "Do you really want to delete all your slots? This action is irreversible.",
    _upload: "Upload your calendar (.ics)",
    _innertitle: "Enable time slots",
    _maxLimitPerSlot: "Limit per time slot ",
    _import_failure: "Couldn't find any eligible time slot to import. Try again later",
    _import_success: " Time slot(s) successfully imported",
    _disabled: "(disabled)",
    _nothing_to_export: "No data to export",
    _export_data: "Export your bookings as .ics",
  },
  eventPad: {
    _save: "Save",
    _duplicate: "Duplicate",
    _create: "Create",
    _created: "Created",
    _modified: "Edited",
    _successmessage: "The event has been successfully modified",
    _privateEvent: "PRIVATE EVENT",
    _publicEvent: "PUBLIC EVENT",
    _event: "event",
    _pagetitlePrivate: "Private event",
    _pagetitlePublic: "Public event",
    _eventtype: "Event type *",
    _eventname: "Event name *",
    _startdate: "Startdate *",
    _enddate: "Enddate *",
    _location: "Location",
    _venue: "Venue",
    _flyer: "Event flyer",
    _description: "Description",
    _locationErrorMessage: "Address not valid",
    _locationStatusSarching: "Verifying",
    _undo: "Delete",
    _clear: "Cancel",
    _next: "Create", // Modifica temporanea finche' non possiamo gestire il back
    _browse: "Choose",
    _loadingCoverMessage: "We're creating the event.",
    _creationFailed: "Creation failed",
    _mandatoryFields: "The fields marked by asterisk (*) are mandatory ",
    _loadingMessage: "Wait",
    _resolvingAddressAlertMessage: "We're verifying the address",
  },
  eventPayable: {
    _title: "Paid event on other systems",
    _subtitle: "Point out that is a paid event on other systems",
    _submit: "Save",
    _label: "Is a paid event?",
    _updateDone: "Updated",
    _error: "Error not handled",
  },
  externalReference: {
    _title: "External reference",
    _subtitle: "External reference Id",
    _placeholder: "External Reference Id",
    _submit: "Save",
    _updateDone: "Updated",
    _error: "Error not handled",
  },
  externalPurchaseEndpoint: {
    _title: "External Purchase",
    _subtitle: "Purchase",
    _placeholder: "URL purchase",
    _submit: "Save",
    _updateDone: "Updated",
    _error: "Error not handled",
  },
  guestlist: {
    _refresh_table: "Refresh the Guest List",
    _title: "Guest list",
    _subtitle: "Here you can manage your guest list",
    _loadingMessage: "Attendere...",
    _add: "Add manually",
    _download: "Download list",
    _upload: "Upload list",
    _hubiloEvent: "Hubilo's Event ID",
    _reload: "Reload",
    _hubiloSaved: "Hubilo's Event ID saved",
    _list: "List",
    _guestnumber: "Number of guest in the list : ",
    _removelist: "Delete list",
    _nodata: "No guest",
    _guestadded: "Guest added",
    _listadded: "List imported",
    _itemCancellato: "Guest deleted",
    _listCancellata: "List deleted",
    _downloadlistAll: "Download all guests",
    _downloadlist: "Download Check-in list",
    _downloadPlusOneList: "Download +1",
    _downloadActivities: "Download activity report",
    _downloadAttachments: "Download attachments",
    _sure:
      "Proceeding you will lose irreversibly all the information of your guests, including RSVP already stored. Are you sure you want to preceed? ",
    _sureForSingleItem:
      "Proceeding you will lose irreversibly all the information of this guest. Are you sure you want to preceed? ",
    _guestupdated: "Guest updated",
    _errorInEditItems: "Cannot proceed. Errors during the updating of the guest info.",
    _cannotProceed: "Cannot proceed",
    _canDeleteGuestWithPurchase: "Cannot proceed. The guest has active purchase!",
    _loadInProgress: "Loading in progress...",
    _attendeePartnersMax: "Partners per person ",
    _attendeePartnersMaxUpdate: "update performed",
    _listEmailsUpdated: "Email list updated",
    _upload_success: "Importing guest list now",
    _upload_failure: "Couldn't upload guest list",
    _maxAttendeeExceeded: "Sorry, the list is full!",
    _maxGuestSizeReached: "Sorry you can't add more guests, list size limit reached, ",
    _discard_message: "Some of your guests were not imported successfully, Click to see why",
    _audience_title: "Filter guests",
    _audience_subtitle: "Select one of the options below to filter your guests",
    _autoPrint: "Auto-Print badges on Check-In",
    _localPrint: "Auto-Print badges on Manual Check-In from web.",
  },
  sponsor:{
    _title: "Sponsor List",
    _subtitle: "Here you can manage your sponsor list",
    _list: "List",
    _addSponsor: "Add Sponsor",
    _sponsoradded: "Sponsor Added",
    _sponsorupdated: "Sponsor Updated",
    _sponsorremoved: "Sponsor Deleted",
    _sponsor: "Sponsor",
    _sponsorName: "Sponsor Name",
    _sponorEmail: "Sponsor Email",
    _maxTickets: "Max number of tickets",
    _numberOfSponsors: "Number of sponsors: ",
    _updated: "Updated",
  },
  header: {
    _language: "language",
    _italian: "italiano",
    _english: "english",
    _francaise: "french",
    _arab: "arab",
    _logout: "Log Out",
    _unmanagederror: "Error not handeld",
    _languagechanged: "Saved",
  },
  insertTicketForm: {
    _title: "Add a ticket",
    _name: "Description *",
    _availability: "Quantity available ",
    _save: "Save",
    _price: "Price *",
    _type: "Ticket type *",
    _pay: "Paid ticket",
    _donation: "Donation",
    _nolimits: "No limit",
    _mandatoryFields: "The fields marked by asterisk (*) are mandatory ",
  },
  listSingleItemPurchaseList: {
    _title: "Insert participant data",
    _name: "Name *",
    _surname: "Surname *",
    _email: "E-Mail",
    _tel: "Phone",
    _company: "Company",
    _nationality: "Nationality",
    _job: "Job",
    _typology: "Customer type",
    _save: "Save",
    _loadingMessage: "Waiting...",
    _mandatoryFields: "Fields with stars are mandatory",
    _unManagedError: "Unexpected error",
    _emailAlreadyPresentError: "Email is already in the list. Operation unsuccessful",
    _emailAlreadyPresentErrorToast: "Contact not inserted",
  },
  listSingleItem: {
    _title: "Add a guest",
    _name: "Name *",
    _surname: "Surname *",
    _email: "Email",
    _tel: "Telephone",
    _clear: "Delete",
    _save: "Save",
    _add_n_save: "Add and save",
    _add_guest: "Add Guest",
    _sureRemove: "You are going to delete the selected item, are you sure?",
    _loadingMessage: "Wait",
    _mandatoryFields: "The fields marked by asterisk (*) are mandatory",
    _unManagedError: "Error not handled",
    _emailAlreadyPresentError: "Contact not saved. Email already present",
    _emailAlreadyPresentErrorToast: "contact not saved",
    _emailSyntaxError: "Email should be like email@example.com",
    _emailSyntaxErrorToast: "Email is invalid",
  },
  listUpload: {
    _title: "Upload a guest list",
    _clear: "Delete",
    _load: "Upload",
    _save: "Save",
    _browse: "Choose",
    _loadingMessage: "Wait",
    _loadingComplete: "Loaded",
    _loadingError: "Loading failed",
    _itemImported: "Imported",
    _mandatoryFields: "No file",
    _unManagedError: "Error not handled",
    _downloadInstruction: "Download template import file",
    _incompleteListUploadWarningTitle: "Import completed", //  Controlla la lista degli elementi scartati.
    _incompleteListUploadWarning: "Some item is not been imported.", // Check the error list
  },
  messages: {
    _uploadsuccess: "Updated",
    _loadingMessage: "Wait",
    _inappmessagesimple: "Thank you for confirming",
    _inappmessagemodified:
      "Thank you for confirming. This message is purely informative. It doesn't involve the purchase and/or the booking confirmation of a ticket access.",
    _inmailmessageintent: "Send a confirmation email once the user rsvp/ purchase ",
    _savesettings: "Save settings",
    _title: "Confirmation email",
    _subtitle: "Set and custom the email your user receive after the purchase of a ticket access",
    _confirmmessagetitle: "Message for the users ",
    _confirmmessagesubtitle: "Set a message your users receive once they rsvp to your event ",
    _name: "Sender Name",
    _email: "Sender Email",
    _customizemsg: "Personalize the message",
    _subject: "Subject",
    _editmsg: "Modify the message",
    _subjectExplanation: "Edit your template to change subject of the Confirmation Email",
    _registrationFormTitle: "Registration Form",
    _registrationFormDescription:
      "Send a confirmation email once your guests register via your form",
    _urlAfterRegisterDescription:
      "Redirect your guests to a specific URL once they confirm their account's email",
    _urlAfterRegister: "Redirect URL",
    _save: "Save",
    _invalidUrl: "Invalid URL",
  },
  buttonTabs: {
    INTERNAL_VIRTUAL_ROOM: "activate your Penguinpass room",
    INTERNAL_REGISTRATION_FORM: "activate your event",
    EXTERNAL_VIRTUAL_ROOM: "activate external virtual room",
    createNewTemplate: "create new",
    useExistingTemplate: "select a template",
    CONFIRMATION_EMAIL_ENABLED: "Yes, create confirmation email",
    CONFIRMATION_EMAIL_DISABLED: "No, it's not required",
    SHOW_CATEGORY_FORM: "create new",
    SHOW_CATEGORY_TABLE: "manage existing ones",
    SHOW_EVENT_GROUP_FORM: "create new",
    SHOW_EVENT_GROUP_TABLE: "manage existing ones",
  },
  virtualRoomSettings: {
    _title: "Virtual Room",
    _subtitle:
      "Activate your Penguinpass virtual room or invite guests to other external virtual rooms.",
    _externalRoomTitle: "External Room",
    _externalRoomSubtitle:
      "Add custom Virtual room URL (Zoom, Microsoft Teams, Google Hangouts, etc.)",
    _internalRoomTitle: "Create a landing page for your virtual room",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "Set meeting time",
    _instantMeeting: "Instant Meeting",
    _meetingTimeSubtitle: "You can select instant meeting to automatically set the time for now",
    _meetingTimeSubtitle2: "Or manually set the time for later",
    _createdRoomsTitle: "Virtual room details",
    _createdRoomsSubtitle: "",
    _previewTitle: "Preview",
    _hour: "hour",
    _hours: "hours",
    _scheduleRoom: "Schedule the room",
    _moreSettingsTitle: "More virtual room settings",
    _authNeededLabel: "Authentication needed",
    _moderatedChatLabel: "Moderated chat",
    _emailRequiredLabel: "Email on join",
    _coverImageLabel: "Upload cover image",
    _authNeededExplanation: "Make the page accessible only to registered users.",
    _moderatedChatExplanation:
      "This feature allows the moderator to approve or decline all the messages sent by guests and presenters",
    _emailRequiredExplanation: "This feature turn on and off a mandatory email field in join page",
    _coverImageExplanation: "Personalise your landing page with a cover image of your brand.",
    _uploadImage: "Upload image (1 MB max)",
    _openRoom: "Open room",
    _createroom: "Create a new landing page",
    _customizeroom: "Customize your virtual room",
    _authneeded: "Auth needed",
    _moderatedchat: "Moderated chat",
    _emailRequired: "Email on join",
    _uploadsuccess: "Updated",
    _loadingMessage: "Wait",
    _savesettings: "Save settings",
    _showPreview: "Show preview",
    _hidePreview: "Hide preview",
    _selectTemplate: "Apply a template",
    _enableCustomVRoom: "Enable customized virtual room",
    _roomscheduling: "Room scheduling",
    _roomschedulingsubtitle: "Here you can schedule when to start your meeting",
    _instantmeeting: "Instant Meeting",
    _uploadCover: "Upload cover image",
    _publishpage: "Publish page",
    _unpublishpage: "Unpublish page",
    _downloadlogs: "Download Logs",
    _goToPage: "Open page in a new Tab",
    _pickNewerDate: "Start date and time must be in the future",
    _instantMeetingWarning: "Please stop the current active rooms first.",
    _pageSlug: "URL Slug",
    _applyPageSlug: "Apply",
    _pageSlugInUse: "URL Slug already in use",
    _pageSlugExplanation: "Customise your page URL",
    _scheduleRoomHalfAdvance:
      "Moderators and presenters(webinar only) will be able to join half hour in advance. Check the room status, it will be active half hour before the scheduled time.",
  },
  registrationFormSettings: {
    _title: "Create your registration form",
    _subtitle:
      "Create your registration form for your event",
    _externalRoomTitle: "External Room",
    _externalRoomSubtitle:
      "Add custom Virtual room URL (Zoom, Microsoft Teams, Google Hangouts, etc.)",
    _internalRoomTitle: "Choose your template",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "Set when the form will be available",
    _instantMeeting: "Activate the form now",
    _meetingTimeSubtitle: "Choose when to activate the form for your guests",
    _meetingTimeSubtitle2: "Or manually set the time for later",
    _createdRoomsTitle: "Publish your form",
    _createdRoomsSubtitle: "",
    _previewTitle: "Preview",
    _hour: "hour",
    _hours: "hours",
    _scheduleRoom: "Activate at selected time",
    _moreSettingsTitle: "More virtual room settings",
    _authNeededLabel: "Authentication needed",
    _moderatedChatLabel: "Moderated chat",
    _emailRequiredLabel: "Email on join",
    _coverImageLabel: "Upload cover image",
    _authNeededExplanation: "Make the page accessible only to registered users.",
    _moderatedChatExplanation:
      "This feature allows the moderator to approve or decline all the messages sent by guests and presenters",
    _emailRequiredExplanation: "This feature turn on and off a mandatory email field in join page",
    _coverImageExplanation: "Personalise your landing page with a cover image of your brand.",
    _uploadImage: "Upload image (1 MB max)",
    _openRoom: "Open room",
    _createroom: "Create a new landing page",
    _customizeroom: "Customize your virtual room",
    _authneeded: "Auth needed",
    _moderatedchat: "Moderated chat",
    _emailRequired: "Email on join",
    _uploadsuccess: "Updated",
    _loadingMessage: "Wait",
    _savesettings: "Save settings",
    _showPreview: "Show preview",
    _hidePreview: "Hide preview",
    _selectTemplate: "Apply a template",
    _enableCustomVRoom: "Enable customized virtual room",
    _roomscheduling: "Room scheduling",
    _roomschedulingsubtitle: "Here you can schedule when to start your meeting",
    _instantmeeting: "Instant Meeting",
    _uploadCover: "Upload cover image",
    _publishpage: "Publish page",
    _unpublishpage: "Unpublish page",
    _downloadlogs: "Download Logs",
    _goToPage: "Show preview",
    _pickNewerDate: "Start date and time must be in the future",
    _instantMeetingWarning: "Please stop the current active rooms first.",
    _pageSlug: "URL Slug",
    _applyPageSlug: "Apply",
    _pageSlugInUse: "URL Slug already in use",
    _pageSlugExplanation: "Customise your page URL",
    _scheduleRoomHalfAdvance:
      "Moderators and presenters(webinar only) will be able to join half hour in advance. Check the room status, it will be active half hour before the scheduled time.",
    _customThankYou: "Customized thank you",
    _customThankYouExplanation: "Customize your thank you page",
    _customThankYouPlaceholder: "Enter your customized thank you page",
    _hideLoginBar: "Disable account login",
    _hideLoginBarExplanation: "Disable the account login in the registration form",
    _optionalEmail: "Optional acquaintances e-mail",
    _optionalEmailExplanation: "Make entering an e-mail optional for acquaintances"
  },
  modalEmailBuilder: {
    _title: "Something wrong...",
    _buttonClose: "Close",
    _unManagedError: "Unexpected error",
    _templateCreationError: "Error : template not created",
    _templateSaved: "Template saved",
    _emailCreationError: "Error : template not created",
    _emailSaved: "Email saved",
    _save: "Save",
    _confirm: "Confirm",
    _update: "Update",
    _cancel: "Cancel",
    _templateName: "Template Name",
    _templateSubject: "Subject of the Email",
    _backToTemplates: "Back To Templates",
    _nameIsMandatory: "Template name and Subject are mandatory",
    _areyousure: "Are you sure",
    _areyousure_cloning: "Do you really want to create a new template using this one?",
    _areyousure_discard: "Do you really want to exit before saving your changes?",
    _notemplatesave: "Template non salvato",
    _templateDelete: "Template deleted",
    _notemplate: "No Template stored",
    _create: "Create",
    _template_sent: "Email Sent!",
    _template_sent_error: "Email couldn't sent successfully !",
    _use_save_button: "Please use the save button on the right instead",
  },
  newEvent: {
    _create: "Create",
    _created: "Created",
    _event: "Event",
    _pagetitlePrivate: "Private event",
    _pagetitlePublic: "Public event",
    _eventtype: "Event type *",
    _eventname: "Event name *",
    _startdate: "Start date *",
    _enddate: "End date *",
    _location: "Address *",
    _venue: "Venue",
    _virtualVenue: "Virtual venue",
    _descriptionIt: "Description (It)",
    _descriptionEn: "Description (En)",
    _undo: "Delete",
    _clear: "Cancel",
    _next: "Create", // Modifica temporanea finche' non possiamo gestire il back
    _loadingCoverMessage: "We're creating the event...",
    _creationFailed: "Creation failed",
    _mandatoryFields: "The fields marked by asterisk (*) are mandatory",
    _placeNotValid: "The location is not valid",
    _rangeNotValid: "The duration of the event cannot be more than 3 days",
  },
  overview: {
    _pending: "Pending",
    _confirmed: "Confirmed",
    _checkin: "Checked-in",
    _checkinPlus: "Checked-in & +1",
    _prediction: "Prediction",
    _total: "All guest",
    _guestsAndCompanions: "Confirmed & +1",
  },
  promoteEvent: {
    _title: "Event promotion",
    _subtitle: "Promote your event. Make it be the first displayed on the app",
    _submit: "Save",
    _label: "Promote this event",
    _updateDone: "Updated",
    _error: "Error not handled",
  },
  purchaselist: {
    _title: "RSVP list",
    _subtitle: "Here you can check the users who have rsvp to your event ",
    _loadingMessage: "Wait...",
    _add: "Add manually",
    _download: "Download list",
    _upload: "Upload list",
    _list: "List",
    _guestnumber: "Number of RSVP : ",
    _removelist: "Delete guest list",
    _nodata: "No RSVP",
    _guestadded: "Guest added",
    _listadded: "List imported",
    _itemCancellato: "Guest deleted",
    _listCancellata: "List deleted",
    _downloadlistAll: "Download All List",
    _downloadlist: "Download check-in only List",
  },
  report: {
    _title: "Statistics report ",
    _subtitle: "Analysis of collected data ",
    _timeLineCheckinChartLegend:
      "The graph shows the develop and the hourly flow at the check in point during the event. ",
    _attendeeConfirmationChartLegend: "The graph shows the daily rsvp number ",
    _export: "Download",
    _attendeeChart: "Attendee list",
    _timeLineAttendeeConfirmationTitle: "Timeline of Attendee Confirmation",
    _timeLineCheckInTitle: "Timeline Check-in",
    _totalAttendeeStatus: "Total Attendee Status",
    _export_request_received: "Your request is taken. We will inform you shortly",
    _export_request_finished: "Your report is ready. Click here to download it",
    _export_failed: "Operation failed",
    _export_empty: "The report you are trying to download is empty",
  },
  sidebar: {
    _dashboard: "Dashboard",
    _newevent: "New Event",
    _downloadpenguinpass: "Guest App",
    _privacypolicy: "Privacy Policy",
    _terms: "Terms and Condition",
    _linkTerms: "https://penguinpass-website.web.app/terms-and-conditions.html",
    _contacts: "Contact",
    _faq: "/help/en/index.html",
    _allevents: "Events",
    _eventArchived: "Archive",
    _linkContacts: "https://www.penguinpass.it/contact-us.html",
    _privacypolicyURL: "https://penguinpass-website.web.app/privacy-policy.html",
    _applestore: "https://itunes.apple.com/us/app/penguinpass/id1050702093?mt=8",
    _googlestore: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight",
    _downloadpenguinpasscheckinURL:
      "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight&hl=en",
    _downloadpenguinpasscheckinURL_android:
      "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight&hl=en",
    _downloadpenguinpasscheckinURL_ios:
      "https://apps.apple.com/us/app/penguin-check-in-light/id1572985276",
    _downloadpenguinpasscheckin_android: "Check-In App Android",
    _downloadpenguinpasscheckin_ios: "Check-In App IOS",
    _downloadpenguinpassbeacon: "Beacon App",
    _downloadpenguinpassbeaconURL:
      "https://itunes.apple.com/us/app/vbpenguinpass/id1204226558?mt=8",
    _members: "My Team",
    _settings: "Settings",
    _logoutConfirm: "Do you really want to log out?",
    _eventGroup: "Event Groups",
  },
  tickets: {
    _title: "Tickets",
    _subtitle: "Manage the ticket list of your event",
    _nodata: "Free event",
    _nodata_payable: "Paid event on other systems",
    _add: "Add a ticket",
    _confirmDelete: "Are you sure you want to delete?",
    _itemCancellato: "Title deleted",
    _itemAdded: "Title added",
  },
  urlPrivacyPolicy: {
    _title: "Privacy Policy",
    _management: "Privacy policy event management",
    _subtitle: "Purchase",
    _placeholder: "URL policy",
    _submit: "Save",
    _updateDone: "Updated",
    _error: "Error not handled",
    _sure:
      "Proceeding you will lose irreversibly all the information of this privacy policy. Are you sure you want to preceed? ",
  },
  visibilityManager: {
    _publish: "Activate the event",
    _hidden: "Deactivate the event",
    _uploadsuccess: "Updated",
    _archivesuccess: "Event Archivied",
    _unarchivesuccess: "Event Unarchivied",
    _error: "Error: fill the manadory fields",
  },
  main: {
    _notauthorizedoption: "Option not valid",
  },
  home: {
    _titleEventList: "Active Events",
  },
  eventCreateList: {
    _event: "Event",
    _createString: "Create your first guest list",
    _prospectList: "Guest list",
    _add: "Add manually",
    _download: "Download list",
    _upload: "Upload list",
    _next: "Next",
    _loadingMessage: "Wait...",
    _sure: "Are you sure you want to proceed?",
    _itemCancellato: "Guest deleted",
  },
  events: {
    _noEvents: "No events found.",
    _progress: "Loading...",
    _all_events: "All events",
    _my_events: "My events",
    _favourites: "Favourites",
    _archived_events: "Archived Events",
    _search_result: "Search Results",
  },
  singleEvent: {
    _event: "Event",
    _createString: "Create your first guest list",
    _prospectList: "Guest list",
    _add: "Add manually",
    _download: "Download list",
    _upload: "Upload list",
    _next: "Next",
    _insertPlace: "Add location",
    _loadingMessage: "Wait...",
    _description: "Description",
    _emailMarketingWarning: "Activate your event to use email marketing feature",
    _updated: "Updated",
  },
  datagrid: {
    _pending: "Pending",
    _confirmed: "Confirmed",
    _checkin: "Check-In",
    _declined: "Decline",
    _will_be_back: "Will be back",
    _checkout: "Check-out",
    _search: "Search",
    _selected: "Guest selected",
    _selectAllGuests: "All",
    _selectAllMarketingGuests: "All eligible Guests",
    _selectPending: "Pending Guests",
    _selectNotRead: "Guests who have not read the previous campaigns",
    _selectNotSend: "Guests not included in previous campaigns",
    _selectNotSendConfirmed: "Confirmed guests not included in previous campaigns",
    _selectUnRead: "Select UnRead Guests only",
    _selectUnSent: "Select UnSent Guests only",
    _selectConfirmed: "Confirmed Guests",
    _selectCheckedIn: "Checked-in Guests",
    _selectCustom: "Select Custom from list",
    _selectCustomField: "Use Custom Fields",
    _selectTimeSlot: "Use Time Slots",
    _edit_emails: "Edit Emails",
    _list_emails: "View List",
    _list_events: "View List",
    _post_confirmation_blocked:
      "Sorry, guest has been already confirmed. Some info can't be modified",
    _guest_name: "Name",
    _guest_surname: "Surname",
    _guest_checkin: "Guest Checked-in",
  },
  emailBuilderSelectMode: {
    _selectTemplateTitle: "Template",
    _selectTemplateText: "Start editing a message from a template",
    _new: "New",
    _newText: "Create new message from scratch",
  },
  textMarketing: {
    _title: "SMS Campaign",
    _titleWA: "WhatsApp Campaign",
    _subtitle: "You can create messages to send to your guest (remember to put the prefix in the format 39 o 0039 and do not use spaces between the numbers)",
    _subtitleWA: `Here you can create the messages that will be sent to your guests via Whatsapp. The text presented below CANNOT be modified. Once sent, the fields between brackets will be filled in automatically with the data of your guests.`,
    _create: "Create a new campaign",
    _header: "Title",
    _body: "Body",
    _sendToApprove: "Save template",
    _edit: "Edit",
    _audience_subtitle: "Filter the list for your sms campaign.",
    _recap: "Recap of your sms campaigns",
    _recapsubtitle: "Keep track of the messages sent, by staying updated on the status",
    _additionalSettingsTitle: "Additional settings",
    _setRsvp:"Add link to RSVP page",
    _setRsvp_explain:"By selecting this field, you can add a personalized link to the RSVP page of your event. The link will be automatically added to the end of the message.",
    _setConfirmation:"Add confirmation SMS",
    _setConfirmation_explain:"By selecting this field, your attendees will receive a confirmation sms with the qr code when they confirm the partecipation."
  },
  directEmailMarketing: {
    _updatingCampaignStats: "updating campaign stats",
    _refreshStats: "Refresh stats",
    _title: "E-mail Campaign",
    _subtitle:
      "You can create emails to invite guests to your event, as well as receive summaries to manage your emails.",
    _warnBeforeDelete: "Do you really want to discard changes and delete your campaign?",
    _warnBeforeSend: "Are you sure you want to send your campaign?",
    _deleteCampaign: "Delete campaign",
    _recap: "Recap of your e-mail campaigns",
    _recapsubtitle: "Keep track of the mails sent, by staying updated on the status",
    _name: "Name (sender)",
    _email: "Email (sender)",
    _subject: "Subject",
    _send: "send campaign",
    _save_changes: "save changes",
    _startfromtemplate: "select template",
    _nocampaign: "no e-mail sent",
    _message: "Message",
    _contacts: "Contacts",
    _logo: "Logo",
    _flyer: "Flyer",
    _clear: "clear",
    _browse: "broser",
    _undo: "clear",
    _join: "Join via email",
    _join_explain:
      "Checking the field your guests will be able to confirm their presence via email",
    _setpartnermax: "+1 confirmation field",
    _setpartnermax_explain:
      "By selecting the field, the guests can indicate the number of their acquaintances, always within the maximum limit set by you. please note, if the guest has already indicated the number of his acquaintances, it is advisable not to modify the data displayed in the list section.",
    _setnote: "+1 name request",
    _setnote_explain:
      "By selecting the field your guests can enter the names of their acquaintances or special requests. there you will find in the notes field of the excel file from the export that you can do at any time from the dashboard.",
    _imgNoticesLogo: "Extensions allowed .jpg/.png, max 1 MB",
    _imgNoticesFlyer: "Extensions allowed .jpg/.png, max 1 MB",
    _create: "Create/Edit Campaign",
    _personalizedLabel: "Customize",
    _personalizedExplain: "Customize the message by adding the name of your recipient",
    _createEmail: "Create Email",
    _modifyEmail: "Edit Email",
    _username: "username",
    _senderName: "Name",
    _save: "Save",
    _modify: "Modify",
    _selectOneVerifiedEmail: "Select one verified email address",
    _selectOneVerifiedDomain: "Select one verified domain address",
    _defaultEmailExplanation1:
      "To modify the email address, you must first verify your domain name or email address.",
    _goToProfileSettings: "Go to Profile Settings",
    _defaultEmailExplanation2: "to modify the email address",
    _editSettings: "Edit Settings",
    _subjectExplanation: "Edit your template to change subject of the campaign",
    _campaign_sent: "Request has been taken. Now we are processing your campaign",
    _recap_name: "Name: ",
    _recap_email: "Email: ",
    _recap_subject: "Subject: ",
    _recap_total_recipient: "Total Recipient(s): ",
    _warning_email: "Please select a verified email address first ",
    _warning_email_2: "Please verify an email address first",
    _warning_domain: "Please verify and/or select a domain and username first",
    _warning_default_email: "Can't send campaign since email address is not valid",
    _warning_recipient: "Please select one or more recipients",
    _warning_template: "Please select or create an email template first",
    _sending_campaign: "Sending...",
    _cancel_campaign: "Cancel",
    _audience_title: "Marketing audience",
    _audience_subtitle: "Filter the list for your email campaign.",
    _emailBuilderTitle: "Create Email",
    _emailBuilderSubtitle: "Use the builder to create your own design.",
    _additionalSettingsTitle: "More Mail Settings",
    _customFields_title: "Custom Fields",
    _customPolicy_title: "Privacy Policy",
    _customFields_explain1:
      "To get further information from your guests, you can add custom fields.",
    _customFields_explain2: "to manage custom fields",
    _customPolicy_explain1: "Set privacy policy to receive information from your guests.",
    _customPolicy_explain2: "to manage privacy policies.",
    _goToSettings: "Go to Settings",
    _confirmationEmail_title: "Confirmation Email",
    _confirmationEmail_subtitle:
      "Once the guest has confimed that they would be attending the event or has RSVP-ed, you can send a confirmation email to ensure them that their response has been acknowledged.",
    _noCampaigns: "No mails have been sent out yet",
  },
  customFieldFilter: {
    _custom_field_filter_header: "Filter",
    _custom_field_filter_question_choose: "Select one of the fields you want to use as filter",
    _custom_field_filter_answer_choose: "Select one of the answers (optional)",
    _custom_field_filter_answer_input: "Type the answer you are looking for (optional)",
    _custom_field_filter_not_valid: "Filters are not valid. Please select one field and one answer",
  },
  searchEvent: {
    _eventType: "Event Type",
    _eventName: "Event Name",
    _search: "Search",
    _description: "Description",
    _address: "Address",
    _sDate: "Start Date",
    _eDate: "End Date",
  },
  categoryManager: {
    _title: "Change Event Category",
    _subtitle: "",
    _eventType: "category",
    _submit: "Save",
  },
  customFieldsManager: {
    _selectMax: "Max Selectable options",
    _insertAnswers: "Insert Answers",
    _title: "Change Custom Fields",
    _subtitle: "Custom fields management",
    _submit: "Save",
    _noCustomFields: "No custom field set",
    _name: "Name: ",
    _label: "Custom field name",
    _answer: "Answer ",
    _create: "Create",
    _sure:
      "Proceeding you will lose irreversibly all the information of this custom field. Are you sure you want to preceed?",
    _createNew: "CREATE A NEW CUSTOM FIELD",
    _chooseExisting: "CREATED CUSTOM FIELDS",
    _updateDone: "Updated",
    _error: "error not handled",
    _alreadyExists: "Custom field already existing",
    _checkInApp: "Show on Check-In App",
    _RSVP: "Show on RSVP",
    _registrationFormPage: "Show on Registration form",
    _hideStaff: "Hide for Staff",
    _validateField: "Validate field",
    _mandatory: "Mandatory",
    OPEN_ANSWER: "Open Answer",
    MULTIPLE_CHOICE: "Multiple Choice",
    TEXT_ONLY: "Text only",
    MATRICOLA: "Registration number",
    _maxSelection: "Max Selectable Answers",
    _type: "Type",
    _limitReached: "Limit reached for selected answer.",
    _validationWithInvalid: "Wrong value provided for the field.",
    _fieldName: "Field",
    _questionTitle: "Question",
    _answerTitle: "Answer",
    _limitAction: "Please change your answer or refresh the page",
    _add_csv:
      "Type here your answers separating them using a comma (ex. answer 1,answer 2,answer 3)",
    _selectTimeSlotWarning: "Please select at least one time slot",
    _confirm_disabling_option:
      "Some of your guests have already selected this answer. If you proceed the answer will no longer be selectable, guests who already selected this answer will not be affected. Do you wish to proceed?",
  },
  teamCustomFieldsManager: {
    _selectMax: "Max Selectable options",
    _insertAnswers: "Insert Answers",
    _title: "Manage Guest Personal informations fields",
    _subtitle: "Personal informations fields management",
    _submit: "Save",
    _noCustomFields: "No Personal informations field set",
    _name: "Name: ",
    _label: "Personal informations field name",
    _answer: "Answer ",
    _create: "Create",
    _sure:
      "Proceeding you will lose irreversibly all the information of this personal informations field. Are you sure you want to preceed?",
    _createNew: "CREATE A NEW PERSONAL INFORMATION FIELD",
    _chooseExisting: "CREATED PERSONAL INFORMATION FIELDS",
    _updateDone: "Updated",
    _error: "error not handled",
    _alreadyExists: "Personal informations field already existing",
    _checkInApp: "Show on Check-In App",
    _RSVP: "Show on RSVP",
    _landingPage: "Show on Landing",
    _hideStaff: "Hide for Staff",
    _mandatory: "Mandatory",
    OPEN_ANSWER: "Open Answer",
    MULTIPLE_CHOICE: "Multiple Choice",
    DATE: "Date",
    _maxSelection: "Max Selectable Answers",
    _type: "Type",
    _limitReached: "Limit reached for selected answer.",
    _questionTitle: "Question",
    _answerTitle: "Answer",
    _limitAction: "Please change your answer or refresh the page",
    _add_csv:
      "Type here your answers separating them using a comma (ex. answer 1,answer 2,answer 3)",
    _selectTimeSlotWarning: "Please select at least one time slot",
    _confirm_disabling_option:
      "Some of your guests have already selected this answer. If you proceed the answer will no longer be selectable, guests who already selected this answer will not be affected. Do you wish to proceed?",
  },
  loginPage: {
    _login_title: "Welcome!",
    _login_description: " ",
    _email_hint: "Email Address",
    _password_hint: "Password",
    _click_here: "Click here",
    _password_recovery: "Forgot password?",
    _submit: "Come in",
    _remember_me: "Remember Me",
    _password_mandatory: "Password is required",
    _email_not_valid: "Email is not valid ",
    _sso: "SSO",
    _confirm: "Confirm",
  },
  registerPage: {
    _register_title: "Sign In",
    _register_title_success: "Welcome!",
    _register_description:
      "Sign up for FREE to start trying Penguinpass and write us if you need information or special requests for your event. We will be happy to explain to you how revolutionary our system is.",
    _email_hint: "Email Address",
    _password_hint: "Password",
    _click_here: "Click here",
    _password_recovery: "Forgot password?",
    _submit: "Submit",
    _remember_me: "Remember Me",
    _privacy_policy_mandatory:
      "You must accept our privacy policy and terms & conditions for registration",
    _email_not_valid: "Email is not valid ",
    _name_surname_mandatory: "Name and Surname is required ",
    _company_mandatory: "Company is required ",
    _password_mandatory: "Password is required",
    _passwords_not_match: "Passwords are not same",
    _p_name: "Name*",
    _p_surname: "Surname*",
    _p_email: "Email*",
    _p_company: "Company Name*",
    _p_vat_number: "Vat Number",
    _p_site: "Website",
    _p_city: "City",
    _p_nation: "Nationality",
    _p_password: "Password*",
    _p_password_confirm: "Password Confirm*",
    _p_description: "If you want, enter a message for us",
    _after_register_title: "Before starting, we need to verify your email address.",
    _after_register_description_1:
      "You will receive an email to your inbox with a link to activate your account.",
    _after_register_description_2: "You can reach us at",
  },
  organizerSettings: {
    _pageTitle: "Settings",
    _tabProfile: "Profile",
    _tabPayment: "Payment Configuration",
    _tabPassword: "Modify Password",
    _tabEventSettings: "Event Setup",
    _tabCustomCategories: "Custom Categories",
    _tabDomain: "Domain Setup",
    _tabUnsubscribers: "Unsubscribers",
    _tabDeleteAccount: "Delete account",
    _profileHeader: "Register info",
    _profileSubHeader: "Summary of personal data",
    _showInApp: "This name will be visible in public pages",
    _profilePersonalSection: "Personal Info",
    _profileCompanySection: "Company Info",
    _profileBannerSection: "Banner",
    _profileBackgroundSection: "Background",
    _placeholderName: "Name",
    _placeholderSurname: "Surname",
    _placeholderCompanyName: "Company",
    _placeholderVatNumber: "Vat Number",
    _placeholderCity: "City",
    _modify: "Modify",
    _submit: "Submit",
    _add_new_email: "Verify Email",
    _add_new_domain: "Verify Domain",
    _passwordHeader: "Modify your password ",
    _domainHeader: "Identity verification",
    _select_identity_method: "Select your identity method",
    _newDomainSection: "Domain name to be verified (example.com)",
    _newEmailSection: "Email address to be verified (name@example.com)",
    _email: "Email",
    _domain: "Domain",
    _placeholderEmail: "Email address to be verified (name@example.com)",
    _placeholderDomain: "Domain name to be verified (example.com)",
    _selectIdentityType: "Select",
    _default: "Default (no verification)",
    _email_recap: "Email identities",
    _domain_recap: "Custom Domains",
    _recap: "Domain identities",
    _recapSubtitle: "Here you can verify your email addresses or custom domains",
    _email_recap_subtitle:
      "Here you can inspect status of your email addresses used in email marketing",
    _mail_from_desc_1:
      "Custom MAIL FROM configuration can help you to increase your email delivery reputation and branding. You should only enable and configure if your DNS provider allows you to modify MX values for a custom subdomain (For example aruba.it doesn't allow you to modify them)",
    _mail_from_desc_2: "- MX value is for sending emails by using your custom domain. ",
    _mail_from_desc_3: "- TXT value is for Sender Policy Framework (SPF) configuration.",
    _domain_desc_1:
      "Penguinpass is using Amazon Web Services (AWS),one of the most reliable solutions in the market, to send emails. In order to send email on behalf of your custom domain;",
    _domain_desc_2:
      "- TXT value is for sending authorization for sending emails on behalf of your domain.",
    _domain_desc_3: "- CNAME values are for DKIM settings of your domain.",
    _domain_desc_4:
      "With those two configuration, you will be able to send email using Penguinpass Email Server without losing capability of sending email with your original email server.",
    _domain_desc_5:
      "All the replies to sent emails will still return to your original email server. To decrease spam rate, CNAME values are also marked as mandatory.",
    _domain_desc_6:
      "After we verify your domain, you will be able to use any username under your domain as email sender such as `any_username@yourdomain.com`.",
    _domain_desc_7:
      "Your verification request will expire after 48 hours. In such cases, you need to submit a new verification request.",
    _read_more: "read more",
    _read_less: "show less",
    _step: "Step",
    _step_1: "Creating domain",
    _step_2: "Modifying DNS configuration",
    _step_3: "Waiting time up to 48h after DNS configuration are modified",
    _refreshIdentities: "Click to refresh",
    _updatingIdentities: "Refreshing",
    _no_data: "No data yet",
    _settingsUpdated: "Settings updated!",
    _profileUpdated: "Profile info updated!",
    _password_empty: "Password is empty!",
    _newPassword_warning_message: "Passwords are not same",
    _password_updated: "Password updated!",
    _same_password: "Please select a password different than previous one",
    _email_exists: "Email already present!",
    _unsubscribersHeader: "Unsubscribers",
    _recap_unsubscribers: "Unsubscribers",
    _recapSubtitle_unsubscribers:
      "Here you can inspect the email addresses unsubscribed to your team",
    _unsubscriber_number: "Number of unsubscribers : ",
    _download_unsubscribers: "Download all list",
    _remove_existing: "Remove your existing identities first",
    _download_record_set: "Download record set as XLSX",
    _deleteDomain: "Delete the domain",
    _warn_before_delete_domain: "Do you really want to delete your domain ?",
    _domain_exists:
      "This domain has already been verified by another user. Please contact us if you are the owner of this domain",
    _email_verification_exists:
      "This email has already been verified by another user. Please contact us if you are the owner of this email",
    _email_syntax_error: "Email is not valid",
    _domain_syntax_error: "Domain is not valid",
    _email_domain_verification_exists:
      "This email's domain has already been verified by another user. Please contact us if you are the owner of this domain.",
    _eventSettingsHeader: "Manage Event Settings",
    _eventSettingsSubtitle:
      "Here you can manage, the peronal informations to ask to your guests when they signup or join to your events.",
    _customCategoriesHeader: "Manage Your Custom Categories",
    _customCategoriesSubtitle:
      "Here you can manage your custom categories to cluster your events however you want.",
    _eventGroupTitle: "Manage Your Events Groups",
    _eventGroupSubtitle:
      "Here you can manage your event groups to control your access management of one or more events.",
  },
  recoveryPassword: {
    _forgot_password_heading_text: "RECOVER PASSWORD",
    _forgot_password_input_text: "Enter recovery Email",
    _forgot_password_reset_text:
      "Enter your email below, we will send you a link to reset it, thanks.",
    _forgot_password_button_text: "RESET",
    _successRecovery_title: "RECOVERY STARTED",
    _successecovery_message:
      "We have sent you a link to recover your password. If you still have problems write to",
    _newPassword_title: "Reset your Password",
    _newPassword_title_update: "Update your password",
    _newPassword_description: "Enter the new password, and then repeat it below to confirm.",
    _newPassword_description_update:
      "Your password is expired. Please enter the new password, and then repeat it below to confirm.",
    _newPassword_summitButton: "Reset your password",
    _newPassword_summitButton_update: "Update your password",
    _newPassword_warning_message: "Passwords are not same",
  },
  verifyAccount: {
    _send_link: "Submit",
    _email_label: "Enter your email",
    _resend_success_title: "Success!",
    _resend_success_subtitle: "Check your inbox. Hopefully, it will be last time!",
    _verification_success_title: "Success!",
    _verification_success_guest:
      "Verification complete! You can return to your application and login",
    _verification_success_organizer:
      "Verification complete! You can go to login page and use dashboard",
    _verification_failed_title: "Failed!",
    _verification_failed: "Verification failed",
    _verification_expired:
      "Verification link is expired. If you want to verify again, please type your email",
    _joinOk: "We also confirm your registration to the event was successfull!",
    _joinErr:
      "We are sorry, the registration to the event was unsuccessfull, please login and try again.",
  },
  termsAndConditions: {
    _terms_accept: "and agree the",
    _privacy_accept: "I have read",
    _privacy_title: "the privacy statement",
    _terms_title: "terms and conditions of service",
    _mandatory: "(mandatory)",
    _linkTerms: "https://penguinpass-website.web.app/terms-and-conditions.html",
    _privacypolicyURL: "https://penguinpass-website.web.app/privacy-policy.html",
    _updates:
      "I authorize the use of my personal data to stay informed of updates about the product",
    _marketing:
      "I authorize the processing of my personal data for marketing purposes, including those of third parties",
  },
  eventCategories: {
    _contentEvent: "CONTENT EVENT",
    _pressDay: "PRESS DAY",
    _fashionShow: "FASHION SHOW",
    _corporateEvent: "CORPORATE EVENT",
    _fashionEvent: "FASHION EVENT",
    _onInvitation: "ON INVITATION",
    _cocktailParty: "COCKTAIL PARTY",
  },
  eventCategoriesAsOnDB: {
    _contentEvent: "CONTENT_EVENT",
    _pressDay: "PRESS_DAY",
    _fashionShow: "FASHION_SHOW",
    _corporateEvent: "CORPORATE_EVENT",
    _fashionEvent: "FASHION_EVENT",
    _onInvitation: "ON_INVITATION",
    _cocktailParty: "COCKTAIL_PARTY",
  },
  verificationResult: {
    _success_title: "SUCCESS!",
    _success_message:
      "Thank you for verification. Now you can use email marketing with style by using your email address!",
    _failure_title: "Sorry!",
    _failure_message: "Something went wrong. Please try again or don't hesitate to contact us",
  },
  joinPage: {
    _join_title_accepted:
      "THANK YOU FOR CONFIRMING, THIS INVITATION IS STRICTLY CONFIDENTIAL AND RESERVED TO:",
    _join_title_declined: "YOU HAVE DECLINED YOUR PARTICIPATION",
    _join_title_already_accept: "YOU HAVE ALREADY CONFIRMED. THANK YOU.",
    _join_title_already_decline: "YOU HAVE ALREADY DECLINED. THANK YOU.",
    _join_reservedfor: "THIS INVITATION IS STRICTLY CONFIDENTIAL AND RESERVED FOR:",
    _join_description:
      "Please read and confirm our privacy policy and terms and conditions in order to complete the process.",
    _join_confirm: "Confirm",
    _join_confirm_decline: "Decline",
    _join_alert_policy: "Mandatory privacy policy still not accepted.",
    _join_alert_fields: "Mandatory fields still not filled.",
    _privacy_policy: "Privacy Policy",
    _custom_fields: "More information",
    _mandatory: "Mandatory",
    _join_error_invalid_invitation: "Invalid invitation",
    _join_error_event_not_found: "Event not found",
    _join_error_event_full: "We are sorry to inform you that our list is now closed.",
    _join_error_guest_not_found: "Guest not found",
    _join_error_policies_not_accepted:
      "Please read and confirm our privacy policy and terms and conditions in order to complete the process.",
    _join_error_unhandled: "Invalid invitation",
    _number_of_guests: "Select the number of your guests",
    _info_of_guests: "Please enter the name of your guests",
    _guest: "Guest",
    _guest_name: "Name",
    _guest_surname: "Surname",
  },
  registrationPage: {
    _eventFull: "Ops, event is full now. Please check later if some seats are back available.",
    _eventFull2:
      "Ops, event is full now. Please check later if some seats are back available or try reducing number of guests you are trying to take with you if any.",
    _title: "Register to join the event",
    _loggedInAs: "You are logged in as",
    _logout: "Logout",
    _alreadyAccount: "Already have an account?",
    _login: "Login",
    _signup: "Sign Up",
    _prefillData: "to prefill with your data",
    _mandatory: "(Mandatory)",
    _optional: "(Optional)",
    _firstName: "First name",
    _lastName: "Last name",
    _password: "Password",
    _retypePassword: "Retype Password",
    _alreadyJoined: "You have already joined to this event.",
    _takeSomeone: "Do you want to take someone with you?",
    _personsWithYou: "Persons to take with you",
    _personsUpTo: "You can take up to",
    _persons: "persons.",
    _person: "Person",
    _register: "Register",
    _thankYouWaiting:
      "Thank you for registering to this event, we have sent you an email to confirm your account and complete your registration to the event.",
    _thankYouWaiting2:
      "Thank you for registering, we have sent you an email to confirm your account.",
    _emailInUse: "Email already registered, please log in to join the event.",
    _emailInUse2: "Email already registered.",
    _thankYou: "Thank you for registering to this event.",
    _basicInformation: "BASIC INFORMATION",
    _setPassword: "SET PASSWORD",
    _moreInformation: "MORE INFORMATION",
    _bookTimeSlots: "BOOK TIME SLOTS",
  },
  unsubscribePage: {
    _title: "Mailing List",
    _action_confirm:
      "Click below if you wish to stop receiving emails from this organizer. You will be able to re-subscribe at any time from this page.",
    _unsubscribe_confirm: "Click to unsubscribe",
    _thank_you_for_unsubscription:
      "You have been removed from this organizer's mailing list, if you wish to re-subscribe click the button below. For more information contact",
    _resubscribe_reverse: "Click to subscribe again",
    _thank_you_for_resubscribe: "Thanks for subscribing!",
    _unsubscribe_reverse: "Undo changes",
    _resubscribe_confirm: "Click to subscribe",
  },
  ElementTable: {
    name: "Name",
    surname: "Surname",
    email: "E-mail",
  },
  teamPage: {
    _new_member: "Add new member email",
    _role: "Role",
    _invite: "Invite",
    _invite_title: "Add member",
    _invite_subtitle:
      "Here you can add new members to your team. They will receive an invitation e-mail",
    _team_table_title: "Team Members",
    _team_table_subtitle: "Here you can inspect/modify your team members",
    _page_title: "My Team",
    _invitation_sent: "Invitation sent!",
    _error_email_invalid: "A valid email is required",
    _error_role_invalid: "Please pick a role",
    _updated: "Updated",
    _warning_remove: "Do you really want to remove",
    _post_remove: "User will be deleted soon",
  },
  modalBulkAction: {
    _user_selected: "user(s) selected",
    _cancel: "Cancel",
    _confirm: "Confirm",
    _search_event: "Search",
    _users_added: "Users successfully added",
    _users_removed: "Users successfully removed",
    _failed: "Operation is unsuccessful",
    addToEvent: "Assign to an event",
    removeFromEvent: "Remove from an event",
    clearSelection: "Clear selection",
    _bulk_action: "Bulk action",
    _options: "Options",
    _warning_select_users: "Select some users first",
    _warning_select_event: "Select an event first",
  },
  errorsNotFound: {
    _page_not_found: "Page not found",
    _page_not_found_desc:
      "This page might be private or you might not have required permission to see it.",
    _go_to_home: "Go to home page",
    _user_not_found: "User not found",
  },
  badgePrinting: {
    _loadingMessage: "Wait",
    _title: "Badge",
    _innertitle: "Enable badge printing",
    _directPrint: "Direct print from app",
    _save: "Save",
    _saved: "Badge saved",
    _reset: "Reset",
    _formTitle: "Title",
    _formDescription: "Description",
    _formPageW: "Page width",
    _formPageH: "Page height",
    _formFileUpload: "Click to upload",
    _formFileUploadTips: "jpg/png files with a size less than 500kb",
    _formTextColor: "Text Color",
    _formBorderColor: "Border Color",
    _formField1: "Field 1",
    _formField2: "Field 2",
    _formCustomLayout: "Layout Code",
  },
  recordings: {
    _page_title: "Recordings",
    _subtitle: "Here you can download all your video room recordings.",
    _warning_remove: "Do you really want to remove",
    _post_remove: "Recording deleted",
  },
  customCategories: {
    _label: "Category name",
    _submit: "Create",
    _created: "Created",
    _failed: "Failed",
    _warnBeforeDelete:
      "Some of your events are using this category. Therefore, this operation will mark the category as deleted and disable it from using further to keep supporting them. Would you like to continue?",
  },
  eventGroup: {
    _newGroup: "Unique name of group",
    _groupName: "Group name",
    _removeFromGroup: "Remove",
    _markAsController: "Mark as controller",
    _markedAsController: "Marked as controller",
    _submit: "Create",
    _created: "Created",
    _save: "Save",
    _editGroup: "Edit event group",
    _cancel: "Cancel",
    _failed: "Failed",
    _addEvent: "Add event",
    _searchEvents: "Search events",
    _eventGroupExist: "This event group name is already exists, try something new",
    _selectController: "Select at least one controller event",
    _selectNonController: "Select at least one non-controller event",
    _eventListEmpty: "Please add event first",
    _groupNameMissing: "Group name is missing",
    _warnBeforeDelete:
      "Are you sure you want to delete this event group? Deleting this event group, will not affect the individual events in this group.",
    _warnBeforeDeletingLastEvent:
      "You can't remove all events inside a group. Consider deleting group instead.",
  },
  notify: {
    _page_title: "Push Notifications",
    _subtitle:
      "Create push notifications for specific events or to share important updates with the guests",
    _no_notifications: "No notifications have been sent yet",
    _create: "Create new",
    _create_section_title: " Create a new push notification",
    _title: "Title",
    _title_placeholder: "Enter the push notification title",
    _body: "Body",
    _body_placeholder: "Notification body text",
    _recap_section_title: "Notification recap",
    _user_group_section_title: "Select the user group",
    _title_recommendation: "We recommend that you use a maximum of 10 words",
    _submit: "Submit",
    _all_users: "All users or guests - (All events) ",
    _subtitle_user_group:
      "Please note that the notifications are restricted to the users who have downloaded the guest application & are attending the event ",
    _warnBeforeSend: "Are you sure to send the notification?",
    _sending: "Sending",
  },
  showOnApp: {
    _title: "Show on App",
    _description: "Show this event in your guest app",
    _no_password: "Disable registration",
  },
  publicPages: {
    _book_test: "Book a test",
    _download_ticket: "Download ticket",
    _certify_test: "Certify test result",
    _join: "Join",
    _reset_password: "Reset password",
    _reset_message: "You will receive an email from mailing@penguinpass.it to set a new password.",
    _password: "Password",
    _forgot_password: "Forgot your password?",
    _back_login: "Back to Login",
    _reset: "Reset",
    _register: "Register",
    _name: "Name",
    _surname: "Surname",
    _email: "Email",
    _test_not_verified: "Test not verified.",
    _test_not_verified_sub:
      "You have to take the test to see the ticket. Please be sure you have taken the test and it's within the requested time frame.",
  },
  c19compliance: {
    _title: "COVID-19 REGULATION",
    _description: "Enable control",
    timePeriod: "Set time period",
    subTimePeriod:
      "Set the time frame before the event during which the test will be considered valid",
  },
  oauth: {
    _redirecting_you: "Please wait, we are redirecting you",
  },
  RSVPImageHeader: {
    _title: "RSVP header Image",
    _innertitle: "Enable Image",
    _subtitle:
      "Here you can add a logo to customize the thank you page which your guests will see when confirming or decling your invitations",
  },
};
